// select component
$modules: () !default;

@mixin exports($name) {
  @if (not index($modules, $name)) {
    $modules: append($modules, $name) !global;
    @content;
  }
}

$mbsc-mobiscroll-accent: #FDD51E !default;
$mbsc-mobiscroll-background: #46565F !default;
$mbsc-mobiscroll-text: #FDD51E !default;

$mbsc-mobiscroll-dark-accent: #FDD51E !default;
$mbsc-mobiscroll-dark-background: #212B30 !default;
$mbsc-mobiscroll-dark-text: #D3D9DA !default;

.mbsc-mobiscroll .app-header {
  background: #FDD51E;
  color: #46565F;
  height: 60px;
}

@font-face {
  font-family: 'FSHackney-Light';
  src: local('FSHackney-Light'), url(./FSHackney-Light.otf) format('otf');
}
@font-face {
  font-family: 'FSHackney-Bold';
  src: local('FSHackney-Bold'), url(./FSHackney-Bold.otf) format('otf');
}


/* Base colors */
$mbsc-mobiscroll-primary: #FDD51E !default;
$mbsc-mobiscroll-secondary: #46565F !default;
$mbsc-mobiscroll-success: #7be166 !default;
$mbsc-mobiscroll-danger: #D43235 !default;
$mbsc-mobiscroll-warning: #f8b042 !default;
$mbsc-mobiscroll-info: #46565F !default;
$mbsc-mobiscroll-light: darken(#fff, 10%) !default;
$mbsc-mobiscroll-dark: #47494A !default;

@function mbsc-mobiscroll-colors($params) {
  $background: map-get($params, background);
  $text: map-get($params, text);
  $accent: map-get($params, accent);

  $input-icon: lighten($text, 20%);
  $color-input: darken($text, 15%);
  $event-border: lighten($text, 25%);
  $lv-item: darken($background, 4%);
  $checked: desaturate(lighten($accent, 25%), 6%);
  $border: '';
  $empty-color: '';
  $input-disabled: '';
  $handle: '';
  $btn-disabled: '';
  $switch: '';
  $btn-light: '';
  $input-box: '';
  $tooltip: '';
  $checkbox: '';
  $empty-color: '';

  // Light background 
  @if (lightness($background) > 50%) {
    $border: darken($background, 17%);
    $empty-color: lighten($text, 20%);
    $input-disabled: darken($background, 13%);
    $handle: darken($background, 8%);
    $btn-disabled: darken($background, 10%);
    $switch: darken($background, 19%);
    $btn-light: darken($background, 3%);
    $input-box: darken($background, 10%);
    $tooltip: darken($background, 4%);
    $checkbox: lighten($background, 3%);
  }

  // Dark background 
  @else {
    $border: lighten($background, 17%);
    $empty-color: $text;
    $input-disabled: lighten($background, 17%);
    $handle: lighten($background, 7%);
    $btn-disabled: lighten($background, 8%);
    $switch: lighten($background, 14%);
    $btn-light: $background;
    $input-box: lighten($background, 10%);
    $tooltip: $background;
    $checkbox: $background;
  }

  @return (border: $border,
  empty-color: $empty-color,
  input-disabled: $input-disabled,
  btn-light: $btn-light,
  btn-disabled: $btn-disabled,
  switch: $switch,
  checked: $checked,
  handle: $handle,
  input-icon: $input-icon,
  input-box: $input-box,
  tooltip: $tooltip,
  color-input: $color-input,
  event-border: $event-border,
  lv-item: $lv-item,
  checkbox: $checkbox,
  /* static colors */
  textarea: #1f1f1f,
  notification: #787878,
  white-text: #ffffff,
  error: #de3226,
  dark-text: #000);
}

@mixin mbsc-mobiscroll-common($theme, $params) {
  @include exports("common.#{$theme}.colors") {
    $colors: mbsc-mobiscroll-colors($params);
    $empty-color: map-get($colors, empty-color);

    .mbsc-#{$theme} {
      .mbsc-empty {
        color: $empty-color;
      }
    }
  }
}


@mixin mbsc-mobiscroll-frame($theme, $params) {
  @include exports("frame.#{$theme}.colors") {

    $background: map-get($params, background);
    $text: map-get($params, text);
    $accent: map-get($params, accent);

    .mbsc-#{$theme} {
      .mbsc-fr-w {
        background: $background;
        color: $text;
      }

      .mbsc-fr-hdr,
      .mbsc-fr-btn {
        color: $accent;
      }

      .mbsc-fr-btn.mbsc-active,
      &.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
        background: rgba($accent, .3);
      }

      .mbsc-fr-arr {
        background: $background;
      }
    }
  }
}




@include exports("common") {
  .mbsc-cloak {
    visibility: hidden !important;
  }

  /* Empty view */

  .mbsc-empty {
    text-align: center;
    margin: 3em;
    color: inherit;
  }

  .mbsc-empty h3 {
    margin: .666666em 0;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    font-weight: normal;
    font-family: inherit;
  }

  .mbsc-empty p {
    margin: 1em 0;
    padding: 0;
    font-size: 1em;
    line-height: 1.5;
  }
}



@include exports("animation") {

  .mbsc-anim-trans .mbsc-fr-scroll {
    overflow: hidden;
  }

  .mbsc-anim-trans-flip .mbsc-fr-persp,
  .mbsc-anim-trans-swing .mbsc-fr-persp {
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }

  .mbsc-anim-trans .mbsc-fr-popup,
  .mbsc-anim-trans .mbsc-fr-overlay {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .mbsc-anim-in .mbsc-fr-popup,
  .mbsc-anim-in .mbsc-fr-overlay {
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-duration: 225ms;
    animation-timing-function: ease-out;
    animation-duration: 225ms;
  }

  .mbsc-anim-out .mbsc-fr-popup,
  .mbsc-anim-out .mbsc-fr-overlay {
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-duration: 195ms;
    animation-timing-function: ease-in;
    animation-duration: 195ms;
  }

  .mbsc-anim-in .mbsc-fr-overlay {
    -webkit-animation-name: mbsc-anim-f-in;
    animation-name: mbsc-anim-f-in;
  }

  .mbsc-anim-out .mbsc-fr-overlay {
    -webkit-animation-name: mbsc-anim-f-out;
    animation-name: mbsc-anim-f-out;
  }

  .mbsc-anim-flip,
  .mbsc-anim-swing,
  .mbsc-anim-slidehorizontal,
  .mbsc-anim-slidevertical,
  .mbsc-anim-slidedown,
  .mbsc-anim-slideup,
  .mbsc-anim-fade {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateX(0);
    backface-visibility: hidden;
    transform: translateX(0);
  }

  .mbsc-anim-swing,
  .mbsc-anim-slidehorizontal,
  .mbsc-anim-slidevertical,
  .mbsc-anim-slidedown,
  .mbsc-anim-slideup,
  .mbsc-anim-fade {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .mbsc-anim-flip,
  .mbsc-anim-pop {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  .mbsc-anim-in .mbsc-anim-pop {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-p-in;
    -webkit-animation-duration: 100ms;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-p-in;
    animation-duration: 100ms;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-pop {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-p-out;
    -webkit-animation-duration: 150ms;
    animation-name: mbsc-anim-p-out;
    animation-duration: 150ms;
  }

  .mbsc-anim-trans-pop .mbsc-fr-overlay {
    -webkit-animation-duration: 150ms;
    animation-duration: 150ms;
  }

  .mbsc-anim-in .mbsc-anim-flip {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-fl-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-fl-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-flip {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-fl-out;
    animation-name: mbsc-anim-fl-out;
  }

  .mbsc-anim-in .mbsc-anim-swing {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sw-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-sw-in;
  }

  .mbsc-anim-out .mbsc-anim-swing {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sw-out;
    animation-name: mbsc-anim-sw-out;
  }

  .mbsc-anim-in .mbsc-anim-slidehorizontal {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sh-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-sh-in;
  }

  .mbsc-anim-out .mbsc-anim-slidehorizontal {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sh-out;
    animation-name: mbsc-anim-sh-out;
  }

  .mbsc-anim-in .mbsc-anim-slidevertical {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sv-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-sv-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-slidevertical {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sv-out;
    animation-name: mbsc-anim-sv-out;
  }

  .mbsc-anim-in .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-sd-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-out;
    -webkit-transform: translateY(-100%);
    animation-name: mbsc-anim-sd-out;
  }

  .mbsc-anim-in .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-su-in;
  }

  .mbsc-anim-out .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-out;
    -webkit-transform: translateY(100%);
    animation-name: mbsc-anim-su-out;
  }

  .mbsc-anim-in .mbsc-anim-fade {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-f-in;
    animation-name: mbsc-anim-f-in;
  }

  .mbsc-anim-out .mbsc-anim-fade {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-f-out;
    animation-name: mbsc-anim-f-out;
  }

  .mbsc-fr-pointer {
    &.mbsc-anim-in .mbsc-anim-slidedown {
      -webkit-animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
      animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
    }

    &.mbsc-anim-out .mbsc-anim-slidedown {
      -webkit-animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
      animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
    }

    &.mbsc-anim-in .mbsc-anim-slideup {
      -webkit-animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
      animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
    }

    &.mbsc-anim-out .mbsc-anim-slideup {
      -webkit-animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
      animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
    }
  }

  /* Fade in */

  @keyframes mbsc-anim-f-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes mbsc-anim-f-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }


  /* Fade out */

  @keyframes mbsc-anim-f-out {
    from {
      visibility: visible;
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @-webkit-keyframes mbsc-anim-f-out {
    from {
      visibility: visible;
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }


  /* Pop in */

  @keyframes mbsc-anim-p-in {
    from {
      opacity: 0;
      transform: scale(0.8);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-webkit-keyframes mbsc-anim-p-in {
    from {
      opacity: 0;
      -webkit-transform: scale(0.8);
    }

    to {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }


  /* Pop out */

  @keyframes mbsc-anim-p-out {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }

  @-webkit-keyframes mbsc-anim-p-out {
    from {
      opacity: 1;
      -webkit-transform: scale(1);
    }

    to {
      opacity: 0;
      -webkit-transform: scale(0.8);
    }
  }


  /* Flip in */

  @keyframes mbsc-anim-fl-in {
    from {
      opacity: 0;
      transform: rotateY(90deg);
    }

    to {
      opacity: 1;
      transform: rotateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-fl-in {
    from {
      opacity: 0;
      -webkit-transform: rotateY(90deg);
    }

    to {
      opacity: 1;
      -webkit-transform: rotateY(0);
    }
  }


  /* Flip out */

  @keyframes mbsc-anim-fl-out {
    from {
      opacity: 1;
      transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      transform: rotateY(-90deg);
    }
  }

  @-webkit-keyframes mbsc-anim-fl-out {
    from {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }
  }


  /* Swing in */

  @keyframes mbsc-anim-sw-in {
    from {
      opacity: 0;
      transform: rotateY(-90deg);
    }

    to {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }

  @-webkit-keyframes mbsc-anim-sw-in {
    from {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }

    to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }
  }


  /* Swing out */

  @keyframes mbsc-anim-sw-out {
    from {
      opacity: 1;
      transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      transform: rotateY(-90deg);
    }
  }

  @-webkit-keyframes mbsc-anim-sw-out {
    from {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }
  }


  /* Slide horizontal in */

  @keyframes mbsc-anim-sh-in {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sh-in {
    from {
      opacity: 0;
      -webkit-transform: translateX(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }


  /* Slide horizontal out */

  @keyframes mbsc-anim-sh-out {
    from {
      opacity: 1;
      transform: translateX(0);
    }

    to {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sh-out {
    from {
      opacity: 1;
      -webkit-transform: translateX(0);
    }

    to {
      opacity: 0;
      -webkit-transform: translateX(100%);
    }
  }


  /* Slide vertical in */

  @keyframes mbsc-anim-sv-in {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sv-in {
    from {
      opacity: 0;
      -webkit-transform: translateY(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide vertical out */

  @keyframes mbsc-anim-sv-out {
    from {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sv-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 0;
      -webkit-transform: translateY(100%);
    }
  }


  /* Slide Down In */

  @keyframes mbsc-anim-sd-in {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sd-in {
    from {
      opacity: 1;
      -webkit-transform: translateY(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide down out */

  @keyframes mbsc-anim-sd-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sd-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(-100%);
    }
  }


  /* Slide Up In */

  @keyframes mbsc-anim-su-in {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-su-in {
    from {
      opacity: 1;
      -webkit-transform: translateY(100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide up out */

  @keyframes mbsc-anim-su-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-su-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(100%);
    }
  }
}



$mbsc-font-path: '' !default;

@include exports("icons") {

    @font-face {
        font-family: 'icons_mobiscroll';
        src: 
        url($mbsc-font-path + 'icons_mobiscroll.woff?cufpbn') format('woff'),
        url($mbsc-font-path + 'icons_mobiscroll.woff') format('woff'),
        url($mbsc-font-path + 'icons_mobiscroll.ttf?cufpbn') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    .mbsc-ic:before {
        font-family: 'icons_mobiscroll';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

/* Icons */
 
.mbsc-ic-arrow-down5::before {
    content: "\ea01";
} 
.mbsc-ic-arrow-left4::before {
    content: "\ea02";
} 
.mbsc-ic-arrow-left5::before {
    content: "\ea03";
} 
.mbsc-ic-arrow-left6::before {
    content: "\ea04";
} 
.mbsc-ic-arrow-right4::before {
    content: "\ea05";
} 
.mbsc-ic-arrow-right5::before {
    content: "\ea06";
} 
.mbsc-ic-arrow-right6::before {
    content: "\ea07";
} 
.mbsc-ic-arrow-up5::before {
    content: "\ea08";
} 
.mbsc-ic-attachment::before {
    content: "\ea09";
} 
.mbsc-ic-backspace::before {
    content: "\ea0a";
} 
.mbsc-ic-backspace3::before {
    content: "\ea0b";
} 
.mbsc-ic-backspace4::before {
    content: "\ea0c";
} 
.mbsc-ic-checkmark::before {
    content: "\ea0d";
} 
.mbsc-ic-connection::before {
    content: "\ea0e";
} 
.mbsc-ic-earth::before {
    content: "\ea0f";
} 
.mbsc-ic-eye-blocked::before {
    content: "\ea10";
} 
.mbsc-ic-eye::before {
    content: "\ea11";
} 
.mbsc-ic-fa-angle-down::before {
    content: "\ea12";
} 
.mbsc-ic-fa-angle-left::before {
    content: "\ea13";
} 
.mbsc-ic-fa-angle-right::before {
    content: "\ea14";
} 
.mbsc-ic-fa-angle-up::before {
    content: "\ea15";
} 
.mbsc-ic-fa-arrow-left::before {
    content: "\ea16";
} 
.mbsc-ic-fa-backward::before {
    content: "\ea17";
} 
.mbsc-ic-fa-ban::before {
    content: "\ea18";
} 
.mbsc-ic-fa-bars::before {
    content: "\ea19";
} 
.mbsc-ic-fa-check::before {
    content: "\ea1a";
} 
.mbsc-ic-fa-chevron-down::before {
    content: "\ea1b";
} 
.mbsc-ic-fa-chevron-left::before {
    content: "\ea1c";
} 
.mbsc-ic-fa-chevron-right::before {
    content: "\ea1d";
} 
.mbsc-ic-fa-chevron-up::before {
    content: "\ea1e";
} 
.mbsc-ic-fa-copyright::before {
    content: "\ea1f";
} 
.mbsc-ic-fa-envelope::before {
    content: "\ea20";
} 
.mbsc-ic-fa-eye::before {
    content: "\ea21";
} 
.mbsc-ic-fa-facebook::before {
    content: "\ea22";
} 
.mbsc-ic-fa-flash::before {
    content: "\ea23";
} 
.mbsc-ic-fa-forward::before {
    content: "\ea24";
} 
.mbsc-ic-fa-futbol-o::before {
    content: "\ea25";
} 
.mbsc-ic-fa-google::before {
    content: "\ea26";
} 
.mbsc-ic-fa-group::before {
    content: "\ea27";
} 
.mbsc-ic-fa-key::before {
    content: "\ea28";
} 
.mbsc-ic-fa-line-chart::before {
    content: "\ea29";
} 
.mbsc-ic-fa-list::before {
    content: "\ea2a";
} 
.mbsc-ic-fa-map-marker::before {
    content: "\ea2b";
} 
.mbsc-ic-fa-microphone::before {
    content: "\ea2c";
} 
.mbsc-ic-fa-pause::before {
    content: "\ea2d";
} 
.mbsc-ic-fa-play::before {
    content: "\ea2e";
} 
.mbsc-ic-fa-question::before {
    content: "\ea2f";
} 
.mbsc-ic-fa-search::before {
    content: "\ea30";
} 
.mbsc-ic-fa-sign-in::before {
    content: "\ea31";
} 
.mbsc-ic-fa-sign-out::before {
    content: "\ea32";
} 
.mbsc-ic-fa-smile-o::before {
    content: "\ea33";
} 
.mbsc-ic-fa-star-o::before {
    content: "\ea34";
} 
.mbsc-ic-fa-star::before {
    content: "\ea35";
} 
.mbsc-ic-fa-thumbs-down::before {
    content: "\ea36";
} 
.mbsc-ic-fa-thumbs-up::before {
    content: "\ea37";
} 
.mbsc-ic-fa-times::before {
    content: "\ea38";
} 
.mbsc-ic-fa-trophy::before {
    content: "\ea39";
} 
.mbsc-ic-fa-user-circle-o::before {
    content: "\ea3a";
} 
.mbsc-ic-fa-user::before {
    content: "\ea3b";
} 
.mbsc-ic-fa-video-camera::before {
    content: "\ea3c";
} 
.mbsc-ic-fa-volume-down::before {
    content: "\ea3d";
} 
.mbsc-ic-fa-volume-off::before {
    content: "\ea3e";
} 
.mbsc-ic-fa-volume-up::before {
    content: "\ea3f";
} 
.mbsc-ic-fa-whatsapp::before {
    content: "\ea40";
} 
.mbsc-ic-facebook::before {
    content: "\ea41";
} 
.mbsc-ic-foundation-female-symbol::before {
    content: "\ea42";
} 
.mbsc-ic-foundation-male-symbol::before {
    content: "\ea43";
} 
.mbsc-ic-foundation-volume-none::before {
    content: "\ea44";
} 
.mbsc-ic-foundation-volume-strike::before {
    content: "\ea45";
} 
.mbsc-ic-foundation-volume::before {
    content: "\ea46";
} 
.mbsc-ic-home::before {
    content: "\ea47";
} 
.mbsc-ic-info2::before {
    content: "\ea48";
} 
.mbsc-ic-instagram::before {
    content: "\ea49";
} 
.mbsc-ic-ion-android-volume::before {
    content: "\ea4a";
} 
.mbsc-ic-ion-close-circled::before {
    content: "\ea4b";
} 
.mbsc-ic-ion-ios7-checkmark-empty::before {
    content: "\ea4c";
} 
.mbsc-ic-ion-ios7-volume-high::before {
    content: "\ea4d";
} 
.mbsc-ic-ion-ios7-volume-low::before {
    content: "\ea4e";
} 
.mbsc-ic-ion-volume-high::before {
    content: "\ea4f";
} 
.mbsc-ic-ion-volume-low::before {
    content: "\ea50";
} 
.mbsc-ic-ion-volume-medium::before {
    content: "\ea51";
} 
.mbsc-ic-ion-volume-mute::before {
    content: "\ea52";
} 
.mbsc-ic-line-t-shirt::before {
    content: "\ea53";
} 
.mbsc-ic-line-video::before {
    content: "\ea54";
} 
.mbsc-ic-loop2::before {
    content: "\ea55";
} 
.mbsc-ic-material-check::before {
    content: "\ea56";
} 
.mbsc-ic-material-close::before {
    content: "\ea57";
} 
.mbsc-ic-material-format-list-numbered::before {
    content: "\ea58";
} 
.mbsc-ic-material-headset-m::before {
    content: "\ea59";
} 
.mbsc-ic-material-menu::before {
    content: "\ea5a";
} 
.mbsc-ic-material-more-horiz::before {
    content: "\ea5b";
} 
.mbsc-ic-material-pause::before {
    content: "\ea5c";
} 
.mbsc-ic-material-skip-next::before {
    content: "\ea5d";
} 
.mbsc-ic-material-timer::before {
    content: "\ea5e";
} 
.mbsc-ic-material-volume-down::before {
    content: "\ea5f";
} 
.mbsc-ic-material-volume-mute::before {
    content: "\ea60";
} 
.mbsc-ic-material-volume-off::before {
    content: "\ea61";
} 
.mbsc-ic-material-volume-up::before {
    content: "\ea62";
} 
.mbsc-ic-minus::before {
    content: "\ea64";
} 
.mbsc-ic-pause2::before {
    content: "\ea63";
} 
.mbsc-ic-play3::before {
    content: "\ea66";
} 
.mbsc-ic-plus::before {
    content: "\ea65";
} 
.mbsc-ic-star::before {
    content: "\ea67";
} 
.mbsc-ic-star3::before {
    content: "\ea68";
} 
.mbsc-ic-stop2::before {
    content: "\ea69";
} 
.mbsc-ic-twitter::before {
    content: "\ea6a";
} 
.mbsc-ic-undo2::before {
    content: "\ea6b";
} 
.mbsc-ic-volume-decrease::before {
    content: "\ea6c";
} 
.mbsc-ic-volume-high::before {
    content: "\ea6d";
} 
.mbsc-ic-volume-increase::before {
    content: "\ea6e";
} 
.mbsc-ic-volume-low::before {
    content: "\ea6f";
} 
.mbsc-ic-volume-medium::before {
    content: "\ea70";
} 
.mbsc-ic-volume-mute::before {
    content: "\ea71";
} 
.mbsc-ic-volume-mute2::before {
    content: "\ea72";
} 
.mbsc-ic-youtube::before {
    content: "\ea73";
}

}

@include exports("frame") {

  .mbsc-fr-w,
  .mbsc-fr-overlay {
    -webkit-transform: translateZ(0);
  }

  .mbsc-fr {
    pointer-events: none;
    z-index: 99998;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
  }

  .mbsc-fr-lock-ctx {
    position: relative;
  }

  .mbsc-fr-lock.mbsc-fr-lock-ios {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
  }

  .mbsc-fr-pos {
    visibility: hidden;
  }

  .mbsc-fr-scroll {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .mbsc-fr-popup {
    max-width: 98%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    font-size: 12px;
    text-shadow: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }

  .mbsc-rtl {
    direction: rtl;
  }


  /* Box sizing */

  .mbsc-fr-popup,
  .mbsc-fr-btn-cont,
  .mbsc-fr-arr {
    box-sizing: border-box;
  }

  .mbsc-fr .mbsc-fr-w {
    box-sizing: content-box;
  }

  .mbsc-fr-w {
    min-width: 256px;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    font-family: FSHackney-Bold, verdana, sans-serif;
  }


  /* Modal overlay */

  .mbsc-fr,
  .mbsc-fr-persp,
  .mbsc-fr-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .mbsc-fr-lock .mbsc-fr-persp {
    -ms-touch-action: none;
    touch-action: none;
  }

  .mbsc-fr-lock-ctx > .mbsc-fr,
  .mbsc-fr-lock-ctx .mbsc-fr-persp,
  .mbsc-fr-lock-ctx .mbsc-fr-overlay {
    position: absolute;
  }

  .mbsc-fr-persp {
    pointer-events: auto;
    overflow: hidden;
  }

  .mbsc-fr-overlay {
    z-index: 1;
    background: rgba(0, 0, 0, .7);
  }


  /* Liquid mode */

  .mbsc-fr-liq .mbsc-fr-popup {
    max-width: 100%;
  }


  /* Top/Bottom mode */

  .mbsc-fr-top .mbsc-fr-popup,
  .mbsc-fr-bottom .mbsc-fr-popup {
    width: 100%;
    max-width: 100%;
  }

  .mbsc-fr-top .mbsc-fr-w,
  .mbsc-fr-bottom .mbsc-fr-w {
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }

  .mbsc-fr-bottom .mbsc-fr-w {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mbsc-fr-top .mbsc-fr-popup {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }


  /* Inline mode */

  .mbsc-fr-inline {
    position: relative;
    pointer-events: auto;
    z-index: 0;
  }

  .mbsc-fr-inline .mbsc-fr-popup {
    position: static;
    max-width: 100%;
  }


  /* Bubble mode */

  .mbsc-fr-bubble,
  .mbsc-fr-bubble .mbsc-fr-persp {
    position: absolute;
  }

  .mbsc-fr-bubble .mbsc-fr-arr-w {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    overflow: hidden;
  }

  .mbsc-fr-bubble-top .mbsc-fr-arr-w {
    top: 100%;
  }

  .mbsc-fr-bubble-bottom .mbsc-fr-arr-w {
    bottom: 100%;
  }

  .mbsc-fr-bubble .mbsc-fr-arr-i {
    margin: 0 1.75em;
    position: relative;
    direction: ltr;
  }

  .mbsc-fr-bubble .mbsc-fr-arr {
    display: block;
  }

  .mbsc-fr-arr {
    display: none;
    position: relative;
    left: 0;
    width: 2em;
    height: 2em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-left: -1em;
  }

  .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: 1.333334em;
  }

  .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: -1.333334em;
  }

  .mbsc-fr-hdn {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
  }


  /* Header */

  .mbsc-fr-hdr {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }


  /* Buttons */

  .mbsc-fr-btn {
    overflow: hidden;
    display: block;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .mbsc-fr-btn-e {
    cursor: pointer;
  }

  .mbsc-fr-btn.mbsc-disabled {
    cursor: not-allowed;
  }


  /* Button container */

  .mbsc-fr-btn-cont {
    display: table;
    width: 100%;
    text-align: center;
    white-space: normal;
  }

  .mbsc-fr-btn-cont .mbsc-disabled {
    opacity: .3;
  }


  /* Button wrapper */

  .mbsc-fr-btn-w {
    vertical-align: top;
    display: table-cell;
    position: relative;
    z-index: 5;
  }

  .mbsc-fr-btn-w .mbsc-fr-btn:before {
    padding: .375em;
  }

  /* Desktop view */

  .mbsc-fr-pointer {

    /* Embedded components */
    &.mbsc-fr .mbsc-fr-w .mbsc-fr-inline .mbsc-fr-w {
      box-shadow: none;
      border-radius: 0;
    }

    .mbsc-ltr .mbsc-fr-w,
    .mbsc-ltr .mbsc-sc-whl .mbsc-sel-gr {
      text-align: left;
    }

    .mbsc-rtl .mbsc-fr-w,
    .mbsc-rtl .mbsc-sc-whl .mbsc-sel-gr {
      text-align: right;
    }

    &.mbsc-fr-top .mbsc-fr-w,
    &.mbsc-fr-bottom .mbsc-fr-w {
      pointer-events: auto;
      display: inline-block;
      margin-top: 3em;
      margin-bottom: 3em;
      max-width: 98%;
    }

    &.mbsc-fr-top .mbsc-fr-popup,
    &.mbsc-fr-bottom .mbsc-fr-popup {
      text-align: center;
      pointer-events: none;
    }

    &.mbsc-fr-bubble .mbsc-fr-arr-w {
      display: none;
    }

    .mbsc-sel-empty {
      text-align: center;
    }
  }
}

@include exports("frame.mobiscroll") {
  .mbsc-mobiscroll {
 
    .mbsc-fr-w {
      min-width: 16em;
      font-size: 16px;
    }

    .mbsc-fr-hdr {
      padding: 0 .6666em;
      padding-top: .6666em;
      font-size: .75em;
      text-transform: uppercase;
      min-height: 2em;
      line-height: 2em;
    }

    .mbsc-fr-btn-cont {
      display: block;
      overflow: hidden;
      text-align: right;
      padding: 0 .5em .5em .5em;
    }

    .mbsc-ltr .mbsc-fr-btn-cont {
      text-align: right;
    }

    .mbsc-rtl .mbsc-fr-btn-cont {
      text-align: left;
    }

    .mbsc-fr-btn-w {
      display: inline-block;
    }

    .mbsc-fr-btn {
      height: 2.5em;
      line-height: 2.5em;
      padding: 0 1em;
      text-transform: uppercase;
    }

    /* Display modes */
    &.mbsc-fr-center .mbsc-fr-w,
    &.mbsc-fr-bubble .mbsc-fr-w {
      border-radius: .25em;
    }

    &.mbsc-fr-no-overlay {
      .mbsc-fr-arr {
        box-shadow: 0 0 1em rgba(0, 0, 0, .2);
      }

      .mbsc-fr-w {
        box-shadow: 0 .125em 1em rgba(0, 0, 0, .3);
      }

      &.mbsc-fr-bubble .mbsc-fr-w {
        border-radius: .25em;
      }
    }
  }

  @include mbsc-mobiscroll-frame(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}





@mixin mbsc-mobiscroll-scroller($theme, $params) {
  @include exports("scroller.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);

    $background: map-get($params, background);
    $accent: map-get($params, accent);
    $text: map-get($params, text);

    .mbsc-#{$theme} {
      .mbsc-sc-lbl {
        color: $accent;
      }

      .mbsc-sc-whl-l {
        border-top: 1px solid $accent;
        border-bottom: 1px solid $accent;
      }

      .mbsc-sc-btn {
        color: $accent;
        background: $background;
      }

      &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
      .mbsc-sc-itm:focus {
        background: rgba($text, .1);
      }

      &.mbsc-no-touch .mbsc-sc-btn:hover:before,
      &.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active,
      .mbsc-sc-btn.mbsc-active:before {
        background: rgba($accent, .3);
      }

      /* Multiple select */
      .mbsc-sc-whl-multi {
        .mbsc-sc-itm-sel:before {
          color: $accent;
        }
      }
    }
  }
}




@include exports("scroller") {

  .mbsc-sc-whl-o,
  .mbsc-sc-btn {
    /* Prevent flickering on animation */
    -webkit-transform: translateZ(0);
  }

  /* Force content box */

  .mbsc-sc .mbsc-sc-whl-c,
  .mbsc-sc .mbsc-sc-whl-l,
  .mbsc-sc .mbsc-sc-whl {
    box-sizing: content-box;
  }

  /* Force border box */

  .mbsc-sc-whl-gr-c,
  .mbsc-sc-itm {
    box-sizing: border-box;
  }

  .mbsc-sc-whl-gr-c {
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
  }

  .mbsc-fr-bottom .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-top .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-inline .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-liq .mbsc-sc-whl-gr-c {
    display: block;
  }

  .mbsc-sc-whl-gr {
    margin: 0 auto;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .mbsc-sc-whl-w {
    max-width: 100%;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
  }

  .mbsc-fr-pointer .mbsc-sc-whl-w,
  .mbsc-fr-liq .mbsc-sc-whl-w {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }

  .mbsc-sc-whl-o {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    display: none;
  }

  .mbsc-sc-whl-l {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: block;
  }

  .mbsc-sc-whl {
    overflow: hidden;
    /* Forces IE to respect overflow hidden while animating */
    /* Looks like this is not needed, also, it brakes rendering on Samsung S5 Mini */
    /* border-radius: 1px; */
    /* Fixes Firefox rendering issues */
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin: -1px 0;
  }

  .mbsc-sc-whl-c {
    position: relative;
    z-index: 1;
    top: 50%;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  .mbsc-sc-whl-sc {
    position: relative;
  }

  .mbsc-sc-itm {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-sc-itm-inv,
  .mbsc-sc-itm-inv-h {
    opacity: .3;
  }

  .mbsc-sc-lbl {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    display: none;
  }

  .mbsc-sc-lbl-v .mbsc-sc-lbl {
    display: block;
  }

  .mbsc-sc-btn {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    cursor: pointer;
    opacity: 1;
    text-align: center;
    transition: opacity .2s linear;
  }

  .mbsc-sc-btn:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .mbsc-sc-whl-a .mbsc-sc-btn {
    opacity: 0;
  }

  .mbsc-sc-btn-plus {
    bottom: 0;
  }

  .mbsc-sc-btn-minus {
    top: 0;
  }

  /* 3D */

  .mbsc-sc-whl-gr-3d {
    -webkit-perspective: 1200px;
    perspective: 1200px;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl {
    /* For iOS to respect z-index */
    overflow: visible;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    overflow: hidden;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl-w,
  .mbsc-sc-whl-gr-3d .mbsc-sc-whl {
    /* For Edge and Firefox */
    transform-style: preserve-3d;
  }

  .mbsc-sc-whl-3d {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .mbsc-sc-itm-3d {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .mbsc-sc-itm-del {
    display: none;
  }

  /* Multiline */

  .mbsc-sc-itm-ml {
    width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }

  /* Multiple */

  .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    text-align: center;
  }

  /* Desktop view */

  .mbsc-fr-pointer {

    .mbsc-fr-w .mbsc-sc-whl-gr-c,
    .mbsc-fr-w .mbsc-sc-whl-gr {
      padding: 0;
    }

    .mbsc-sc-whl-gr-c:first-child:last-child {
      display: block;
    }
  }

  .mbsc-sc-bar-c {
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10px;
    opacity: 0;
    background: rgba(0, 0, 0, .05);
    transform: translateZ(0);
    transition: opacity .2s;
  }

  .mbsc-sc-bar {
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 100%;
  }

  .mbsc-sc-bar:after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background: rgba(0, 0, 0, .5);
    border-radius: 3px;
  }

  .mbsc-sc-whl-w:hover .mbsc-sc-bar-c,
  .mbsc-sc-whl-anim .mbsc-sc-bar-c {
    opacity: 1;
  }
}


@include exports("scroller.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-sc-whl-gr {
      padding: .5em .25em;
    }

    .mbsc-sc-whl-w {
      margin: 0 .25em;
    }

    .mbsc-sc-lbl-v .mbsc-sc-whl-w {
      margin-top: 1.875em;
    }

    .mbsc-sc-lbl {
      font-size: .75em;
      line-height: 2.5em;
      text-transform: uppercase;
    }

    .mbsc-sc-cp .mbsc-sc-whl-w {
      padding: 2em 0;
    }

    .mbsc-sc-btn {
      height: 2em;
      line-height: 2em;
    }

    .mbsc-sc-btn:before {
      font-size: 1.5em;
    }

    .mbsc-sc-itm {
      padding: 0 .25em;
      font-size: 1.375em;
    }

    &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
    .mbsc-sc-itm:focus {
      outline: 0;
    }

    /* Multiple select */
    .mbsc-sc-whl-multi {
      .mbsc-sc-itm {
        padding: 0 1.818181em;
      }

      .mbsc-sc-itm-sel:before {
        font-size: 1.818181em;
      }
    }

    /* Desktop view */
    &.mbsc-fr-pointer {
      .mbsc-sc-lbl {
        padding-left: 1.666667em;
        padding-right: 1.666667em;
      }

      .mbsc-sc-whl-w {
        margin-left: 0;
        margin-right: 0;
      }

      .mbsc-sc-itm {
        font-size: 1em;
        padding: 0 1.25em;
      }

      .mbsc-sc-whl-multi {
        .mbsc-sc-itm {
          padding: 0 2.5em;
        }

        .mbsc-sc-itm-sel:before {
          font-size: 2em;
        }
      }

      .mbsc-ltr .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        left: .125em;
      }

      .mbsc-rtl .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        right: .125em;
      }
    }
  }

  @include mbsc-mobiscroll-scroller(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}





@mixin mbsc-mobiscroll-input($theme, $params) {
  @include exports("input.#{$theme}.colors") {

    $background: map-get($params, background);
    $accent: map-get($params, accent);
    $text: map-get($params, text);

    $colors: mbsc-mobiscroll-colors($params);
    $input-icon: map-get($colors, input-icon);
    $input-box: map-get($colors, input-box);
    $error: map-get($colors, error);
    $color-input: map-get($colors, color-input);
    $white-text: map-get($colors, light);

    .mbsc-#{$theme} {

      /* Textbox */
      &.mbsc-input .mbsc-control {
        border-bottom: 1px solid $input-icon;
        color: $color-input;
      }

      &.mbsc-input .mbsc-control:focus,
      &.mbsc-input select:focus ~ input .mbsc-input textarea:focus {
        border-color: $accent;
      }

      .mbsc-input-ic {
        color: $input-icon;
      }

      /* Box Input */
      &.mbsc-input-box {
        &.mbsc-input .mbsc-input-wrap {
          background: $input-box;
        }
      }

      /* Outline Input */
      &.mbsc-input-outline {

        &.mbsc-input .mbsc-control {
          border: 1px solid $input-icon;
        }

        &.mbsc-input .mbsc-label {
          background: $background;
        }

        &.mbsc-label-inline.mbsc-input .mbsc-label,
        &.mbsc-label-floating:not(.mbsc-label-floating-active).mbsc-input .mbsc-label {
          background: none;
        }

        &.mbsc-input .mbsc-control:focus,
        &.mbsc-input select:focus ~ input,
        &.mbsc-err input {
          border-color: $accent;
        }

        &.mbsc-input textarea:focus {
          border-color: $accent;
        }
      }

      &.mbsc-input.mbsc-err input,
      &.mbsc-input.mbsc-err textarea {
        border-color: $error;
      }
    }
  }
}



@include exports("input") {
  @keyframes autofill {
    from {
      opacity: 1;
    }

    to {
      opacity: 1;
    }
  }

  .mbsc-input {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .mbsc-input .mbsc-control:-webkit-autofill {
    animation-name: autofill;
  }

  .mbsc-input input,
  .mbsc-input select,
  .mbsc-input textarea {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .mbsc-input-wrap,
  .mbsc-input input,
  .mbsc-input textarea {
    box-sizing: border-box;
  }

  .mbsc-input input,
  .mbsc-input select,
  .mbsc-input textarea {
    display: block;
    width: 100%;
    font-size: 1em;
  }

  .mbsc-input input:focus,
  .mbsc-input select:focus,
  .mbsc-input textarea:focus {
    outline: 0;
  }

  .mbsc-input-wrap {
    position: relative;
    display: block;
  }

  /* Textbox, textarea, select */

  .mbsc-input .mbsc-label {
    font-size: .875em;
    white-space: nowrap;
  }

  .mbsc-input-ic {
    position: absolute;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
  }

  .mbsc-input-toggle {
    cursor: pointer;
  }

  .mbsc-ic-right .mbsc-input-ic {
    right: .5em;
  }

  .mbsc-ic-left .mbsc-input-ic {
    left: .5em;
  }

  .mbsc-ic-right input {
    padding-right: 2.4em;
  }

  .mbsc-ic-left input {
    padding-left: 2.4em;
  }

  /* Inline labels */

  .mbsc-label-inline.mbsc-input.mbsc-control-w {

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    .mbsc-input-wrap {
      -webkit-box-flex: 1;
      -webkit-flex: 1 auto;
      -moz-box-flex: 1;
      -moz-flex: 1 auto;
      -ms-flex: 1 auto;
      flex: 1 auto;
    }

    .mbsc-label {
      position: static;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -moz-box-flex: 0;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      font-size: 1em;
      width: 30%;
      max-width: 12.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: content-box;
    }
  }

  /* Floating labels */

  .mbsc-label-floating.mbsc-input {
    .mbsc-label {
      pointer-events: none;
      transition: transform .2s;
    }

    .mbsc-label~.mbsc-input-wrap {

      /* WebKit browsers */
      .mbsc-control::-webkit-input-placeholder {
        opacity: 0;
      }

      /* Mozilla Firefox 4 to 18 */
      .mbsc-control:-moz-placeholder {
        opacity: 0;
      }

      /* Mozilla Firefox 19+ */
      .mbsc-control::-moz-placeholder {
        opacity: 0;
      }

      /* Internet Explorer 10+ */
      .mbsc-control:-ms-input-placeholder {
        opacity: 0;
      }
    }

    &.mbsc-label-floating-active .mbsc-label~.mbsc-input-wrap {

      /* WebKit browsers */
      .mbsc-control::-webkit-input-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }

      /* Mozilla Firefox 4 to 18 */
      .mbsc-control:-moz-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }

      /* Mozilla Firefox 19+ */
      .mbsc-control::-moz-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }

      /* Internet Explorer 10+ */
      .mbsc-control:-ms-input-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }
    }
  }

  .mbsc-ltr.mbsc-label-floating.mbsc-input .mbsc-label {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }

  .mbsc-rtl.mbsc-label-floating.mbsc-input .mbsc-label {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }

  /* file type */
  .mbsc-input-wrap .mbsc-control[type="file"] {
    position: absolute;
    left: 0;
    opacity: 0;
  }
}

@include exports("input.mobiscroll") {
  .mbsc-mobiscroll {

    /* Textbox */
    &.mbsc-input input,
    &.mbsc-input textarea,
    &.mbsc-color-input {
      height: 2.125em;
      padding: 0 0 1px 0;
      background: transparent;
      font-size: 1em;
    }

    &.mbsc-input input:disabled,
    &.mbsc-input textarea:disabled,
    &.mbsc-input input:disabled ~ .mbsc-color-input,
    &.mbsc-input .mbsc-control:disabled ~ input,
    &.mbsc-input .mbsc-control:disabled ~ .mbsc-select-ic {
      opacity: .4;
    }

    /* Input */
    &.mbsc-input {
      margin: 1.5em 1em;

      .mbsc-label ~ .mbsc-input-wrap {
        padding-top: .875em;

        .mbsc-input-ic {
          top: .9375em;
        }
      }
    }

    .mbsc-input-ic {
      top: .0625em;
      margin: -2px 0 0 0;
    }

    /* Textarea */
    &.mbsc-input textarea {
      padding-top: .25em;
    }

    /* Select */
    .mbsc-select-ic {
      position: absolute;
      display: block;
      height: 1.25em;
      width: 1.25em;
      top: .5625em;
      text-align: center;
    }

    &.mbsc-ltr .mbsc-select-ic {
      left: auto;
      right: .6875em;
    }

    &.mbsc-rtl .mbsc-select-ic {
      right: auto;
      left: .6875em;
    }

    .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
      top: 1.4375em;
    }

    &.mbsc-ltr.mbsc-select {
      input {
        padding-right: 1.25em;
      }

      &.mbsc-ic-right input {
        padding-right: 3em;
      }

      .mbsc-input-wrap .mbsc-select-ic {
        left: auto;
        right: 0;
      }

      &.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
        right: 1.75em;
      }
    }

    &.mbsc-rtl.mbsc-select {
      input {
        padding-left: 1.25em;
      }

      &.mbsc-ic-right input {
        padding-left: 3em;
      }

      .mbsc-input-wrap .mbsc-select-ic {
        right: auto;
        left: 0;
      }

      &.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
        left: 1.75em;
      }
    }

    /* Select inline */
    .mbsc-select-inline .mbsc-select-ic {
      display: none;
    }

    /* LTR left icon */
    &.mbsc-ltr.mbsc-input.mbsc-ic-left {

      input,
      textarea {
        padding-left: 1.625em;
      }

      .mbsc-left-ic {
        right: auto;
        left: -.375em
      }

      .mbsc-label,
      .mbsc-err-msg {
        left: 2.166667em;
      }
    }

    /* LTR right icon */
    &.mbsc-ltr.mbsc-input.mbsc-ic-right {

      input,
      textarea {
        padding-right: 1.625em;
      }

      .mbsc-right-ic {
        left: auto;
        right: -.375em
      }
    }

    /* RTL left icon */
    &.mbsc-rtl.mbsc-input.mbsc-ic-left {

      input,
      textarea {
        padding-right: 1.625em;
      }

      .mbsc-left-ic {
        left: auto;
        right: -.375em
      }

      .mbsc-label,
      .mbsc-err-msg {
        right: 2.166667em;
      }
    }

    /* RTL right icon */
    &.mbsc-rtl.mbsc-input.mbsc-ic-right {

      input,
      textarea {
        padding-left: 1.625em;
      }

      .mbsc-right-ic {
        right: auto;
        left: -.375em
      }
    }

    &.mbsc-input .mbsc-label {
      position: absolute;
      top: 0;
      font-size: .75em;
    }

    /* Inline labels */
    &.mbsc-label-inline.mbsc-input {
      .mbsc-label {
        line-height: 2em;
      }

      .mbsc-label + .mbsc-input-wrap {
        padding-top: 0;

        .mbsc-input-ic {
          top: .0625em;
        }
      }

      &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
        top: .5625em;
      }
    }

    /* Floating labels */
    &.mbsc-label-floating.mbsc-input {
      .mbsc-label {
        font-size: 1em;
        line-height: 2em;
        top: .875em;
      }

      &.mbsc-label-floating-active .mbsc-label {
        -webkit-transform: translateY(-1.125em) scale(.75);
        transform: translateY(-1.125em) scale(.75);
      }
    }

    &.mbsc-ltr.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
      left: 1.625em;
    }

    &.mbsc-rtl.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
      right: 1.625em;
    }

    /* Box Input */
    &.mbsc-input-box {
      &.mbsc-input .mbsc-input-wrap {
        border-radius: 4px 4px 0 0;
      }

      &.mbsc-input input,
      &.mbsc-input .mbsc-color-input {
        height: 2.25em;
        padding: .0625em 1em;
        border-radius: 4px 4px 0 0;
      }

      &.mbsc-input textarea {
        padding: .375em 1em .0625em 1em;
        height: 2.25em;
      }

      &.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
        padding-top: 0;

        input,
        .mbsc-color-input {
          padding-top: 1.25em;
          height: 3.5em;
        }

        textarea:focus {
          padding-bottom: 0;
        }

        .mbsc-input-ic {
          top: .875em;
        }
      }

      .mbsc-input-ic {
        top: .25em;
      }

      &.mbsc-input .mbsc-label {
        padding: .666667em 0;
        z-index: 1;
        line-height: normal;
      }

      &.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
        padding-top: 1.625em;

        textarea {
          height: 1.866667em;
          padding-top: 0;
        }
      }

      &.mbsc-select {
        .mbsc-input-wrap .mbsc-select-ic {
          top: .625em;
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: 1.375em;
        }
      }

      /* Inline labels */
      &.mbsc-label-inline {
        .mbsc-label {
          padding-top: .125em;
          padding-bottom: .125em;
          line-height: 2em;
        }

        .mbsc-label ~ .mbsc-input-wrap {
          padding-top: 0;

          input,
          .mbsc-color-input {
            height: 2.25em;
            padding-top: .0625em;
          }
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
          top: .1875em;
        }

        &.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
          padding-top: .375em;
        }

        &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: .625em;
        }
      }

      /* Floating labels */
      &.mbsc-label-floating {
        .mbsc-label {
          top: .125em;
          line-height: 2em;
        }

        &.mbsc-label-floating-active .mbsc-label {
          line-height: normal;
          -webkit-transform: translateY(-.125em) scale(.75);
          transform: translateY(-.125em) scale(.75);
        }
      }
    }

    &.mbsc-ltr.mbsc-input-box {
      .mbsc-err-msg {
        left: 1.333334em;
      }

      .mbsc-label {
        left: 1.333334em;
        right: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          left: 4em;
        }

        .mbsc-label {
          left: 4em;
        }

        .mbsc-left-ic {
          left: .5em;
        }

        input,
        textarea {
          padding-left: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-right: 3em;
        }

        .mbsc-right-ic {
          right: .5em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          right: 2em;
        }
      }

      &.mbsc-select {
        input {
          padding-right: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          right: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-right: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            right: 2.75em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        left: 1em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        left: 3em;
      }
    }

    &.mbsc-rtl.mbsc-input-box {
      .mbsc-err-msg {
        right: 1.333334em;
      }

      .mbsc-label {
        right: 1.333334em;
        left: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          right: 4em;
        }

        .mbsc-label {
          right: 4em;
        }

        .mbsc-left-ic {
          right: .5em;
        }

        input,
        textarea {
          padding-right: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-left: 3em;
        }

        .mbsc-right-ic {
          left: .5em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          left: 2em;
        }
      }

      &.mbsc-select {
        input {
          padding-left: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          left: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-left: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            left: 2.75em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        right: 1em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        right: 3em;
      }
    }

    /* Outline Input */
    &.mbsc-input-outline {

      &.mbsc-input input,
      &.mbsc-input textarea,
      &.mbsc-input .mbsc-color-input {
        border-radius: 4px;
        height: 3.375em;
      }

      &.mbsc-input input,
      &.mbsc-input .mbsc-color-input {
        padding: .0625em 1em;
      }

      &.mbsc-input textarea {
        padding: .875em 1em .4375em 1em;
      }

      &.mbsc-input .mbsc-label {
        top: -.166667em;
        z-index: 1;
        padding: 0 .333334em;
      }

      .mbsc-input-ic {
        top: .8125em;
      }

      &.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
        padding-top: .25em;

        .mbsc-input-ic {
          top: 1.0625em;
        }
      }

      &.mbsc-input input:focus,
      &.mbsc-input select:focus ~ input,
      &.mbsc-err input {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.mbsc-err input,
      &.mbsc-err textarea {
        border-color: #de3226;
      }

      &.mbsc-select {
        .mbsc-input-wrap .mbsc-select-ic {
          top: 1.3125em;
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: 1.5625em;
        }
      }

      /* Inline labels */
      &.mbsc-label-inline {
        .mbsc-label {
          line-height: 3.375em;
        }

        .mbsc-label ~ .mbsc-input-wrap {
          padding-top: 0;
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
          top: .8125em;
        }

        &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: 1.3125em;
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input {
        .mbsc-label {
          top: .9375em;
          padding: 0 .25em;
        }

        &.mbsc-label-floating-active .mbsc-label {
          -webkit-transform: translateY(-1.375em) scale(.75);
          transform: translateY(-1.375em) scale(.75);
        }
      }
    }

    &.mbsc-ltr.mbsc-input-outline {
      .mbsc-err-msg {
        left: 1.416667em;
      }

      .mbsc-label {
        left: 1.083334em;
        right: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          left: 4.083334em;
        }

        .mbsc-label {
          left: 3.75em;
        }

        .mbsc-left-ic {
          left: .5em;
        }

        input,
        textarea {
          padding-left: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-right: 3em;
        }

        .mbsc-right-ic {
          right: .5em;
        }
      }

      &.mbsc-select {
        input {
          padding-right: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          right: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-right: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            right: 3em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        left: .875em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        left: 2.875em;
      }
    }

    &.mbsc-rtl.mbsc-input-outline {
      .mbsc-err-msg {
        right: 1.416667em;
      }

      .mbsc-label {
        right: 1.083334em;
        left: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          right: 4.083334em;
        }

        .mbsc-label {
          right: 3.75em;
        }

        .mbsc-left-ic {
          right: .5em;
        }

        input,
        textarea {
          padding-right: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-left: 3em;
        }

        .mbsc-right-ic {
          left: .5em;
        }
      }

      &.mbsc-select {
        input {
          padding-left: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          left: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-left: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            left: 3em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        right: .875em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        right: 2.875em;
      }
    }
  }

  @include mbsc-mobiscroll-input(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}






@mixin mbsc-mobiscroll-select($theme, $params) {
  @include exports("select.#{$theme}.colors") {

    @include mbsc-mobiscroll-scroller($theme, $params);
    @include mbsc-mobiscroll-input($theme, $params);

    $text: map-get($params, text);
    $accent: map-get($params, accent);

    .mbsc-#{$theme} {
      .mbsc-sel-gr-whl.mbsc-sel-one .mbsc-sc-itm-sel {
        color: $accent;
      }

      &.mbsc-fr-pointer .mbsc-sel-one.mbsc-sc-whl-multi .mbsc-sc-itm-sel {
        color: $text;
        background: rgba($accent, .3);
      }
    }
  }
}





@include exports("select") {
  .mbsc-sel-hdn {
    position: absolute;
    height: 1px !important;
    width: 1px !important;
    margin: 0 !important;
    padding: 0 !important;
    left: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .mbsc-sel .mbsc-sel-gr {
    padding: 0 .25em;
    opacity: 1;
    font-weight: bold;
    text-align: left;
  }

  .mbsc-sel .mbsc-fr-c {
    position: relative;
  }

  .mbsc-sel-multi .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: none;
  }

  .mbsc-sel-empty {
    display: none;
    opacity: .6;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2em;
    margin-top: -1em;
    font-size: 1.5em;
    line-height: 2em;
  }

  .mbsc-sel-empty-v {
    display: block;
  }

  .mbsc-sel-filter-clear {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1.5em;
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.125em;
  }

  .mbsc-sel-filter-show-clear {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .mbsc-sel-input-wrap {
    position: relative;
  }

  .mbsc-sel .mbsc-ltr .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    padding-right: 1.5em;
  }

  .mbsc-sel .mbsc-rtl .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    padding-left: 1.5em;
  }

  .mbsc-ltr .mbsc-sel-filter-clear {
    right: 0;
  }

  .mbsc-rtl .mbsc-sel-filter-clear {
    left: 0;
  }

  .mbsc-sel.mbsc-fr-pointer.mbsc-no-touch .mbsc-sc-whl .mbsc-sc-itm.mbsc-sel-gr:hover {
    background: none;
  }
}


@include exports("select.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-sc-whl .mbsc-sel-gr {
      padding: 0 .25em;
      font-size: 1.125em;
    }

    .mbsc-sel-gr-whl {
      .mbsc-sc-itm {
        padding: 0 .25em;
      }

      .mbsc-sc-itm::before {
        display: none;
      }
    }

    &.mbsc-fr-pointer {

      /* Single select */
      .mbsc-sel-one {
        .mbsc-sc-itm {
          padding: 0 1.25em;
        }

        .mbsc-sc-itm-sel:before {
          display: none;
        }
      }

      /* Group headers */
      .mbsc-sc-itm.mbsc-sel-gr-itm {
        padding: 0 2.5em;
      }
    }
  }

  @include mbsc-mobiscroll-select(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// datetime component






@mixin mbsc-mobiscroll-datetime($theme, $params) {
  @include exports("datetime.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}





@include exports("datetime.mobiscroll") {
  @include mbsc-mobiscroll-datetime(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// image component






@mixin mbsc-mobiscroll-image($theme, $params) {
  @include exports("image.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}




@include exports("image") {
  .mbsc-ltr .mbsc-img-w {
    text-align: left;
  }

  .mbsc-ltr .mbsc-img-txt {
    margin: 0 0 0 .5em;
  }

  .mbsc-rtl .mbsc-img-w {
    text-align: right;
  }

  .mbsc-rtl .mbsc-img-txt {
    margin: 0 .5em 0 0;
  }

  .mbsc-img-w .mbsc-ic {
    display: inline-block;
    margin: 0 .5em;
  }

  .mbsc-img-txt {
    display: inline-block;
  }

  .mbsc-img-c {
    min-width: 50px;
    height: 28px;
    margin-top: -2px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: normal;
  }

  .mbsc-img {
    max-height: 28px;
  }
}


@include exports("image.mobiscroll") {
  @include mbsc-mobiscroll-image(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// treelist component






@mixin mbsc-mobiscroll-treelist($theme, $params) {
  @include exports("treelist.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}





@include exports("treelist.mobiscroll") {
  @include mbsc-mobiscroll-treelist(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// calendar component







@mixin mbsc-mobiscroll-calbase($theme, $params) {
  @include exports("calbase.#{$theme}.colors") {

    @include mbsc-mobiscroll-scroller($theme, $params);

    $background: map-get($params, background);
    $text: map-get($params, text);
    $accent: map-get($params, accent);

    .mbsc-#{$theme} {
      .mbsc-cal-picker {
        background: $background;
      }

      .mbsc-cal-days > div {
        border-bottom: 1px solid $accent;
        color: $accent;
      }

      .mbsc-cal-today {
        color: $accent;
      }

      /* Hover, focus */
      &.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
      &.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
      .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
        background: rgba($accent, .3);
      }

      /* Selected */
      .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
        background: $accent;
        color: $background;
      }

      .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
        border-color: $accent;
      }

      /* Week numbers */
      .mbsc-cal-week-nr {
        color: $accent;
      }

      .mbsc-cal-tab {
        border: 1px solid $accent;
        color: $text;
      }

      .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
        background: $accent;
        color: $background;
      }

      /* Marks */
      .mbsc-cal-mark {
        background: $text;
      }

      .mbsc-cal-txt {
        color: $background;
        background: $text;
      }

      .mbsc-cal-icons {
        color: $text;
      }

    }
  }
}




@include exports("calbase") {
  /* Box sizing */

  .mbsc-cal-slide,
  .mbsc-cal-day,
  .mbsc-cal-day-i {
    box-sizing: border-box;
  }

  .mbsc-cal .mbsc-cal-day-date {
    text-align: center;
    box-sizing: content-box;
  }

  /* Flex layout */

  .mbsc-cal-hdr,
  .mbsc-cal-btn-w,
  .mbsc-cal-days-c,
  .mbsc-cal-days,
  .mbsc-cal-tabbed .mbsc-fr-c .mbsc-sc-whl-gr-c {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .mbsc-cal-btn-w,
  .mbsc-cal-month,
  .mbsc-cal-year,
  .mbsc-cal-days,
  .mbsc-cal-days > div {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }

  .mbsc-cal-c {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }

  /* Header */

  .mbsc-cal-btn-w {
    width: 50%;
  }

  .mbsc-cal-month,
  .mbsc-cal-year {
    width: 1%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  .mbsc-cal-btn.mbsc-disabled {
    opacity: .3;
    cursor: not-allowed;
  }

  /* Week days */

  .mbsc-cal-days {
    line-height: 1.875em;
    text-align: center;
  }

  .mbsc-cal-days > div {
    width: 14.285715%;
  }

  .mbsc-cal-body {
    position: relative;
  }

  .mbsc-cal-scroll-c {
    overflow: hidden;
    height: 100%;
  }

  .mbsc-cal-scroll {
    width: 100%;
    height: 100%;
  }

  .mbsc-cal-slide {
    position: absolute;
    z-index: 0; // Needed by Edge
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .mbsc-cal-slide:first-child {
    position: relative;
  }

  .mbsc-cal-table {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
    // Needed for iOS Safari to prevent animation flicker
    -webkit-transform: translateZ(0);
  }

  .mbsc-cal-row {
    display: table-row;
  }

  .mbsc-cal-cell {
    display: table-cell;
    vertical-align: middle;

    &:focus {
      outline: 0;
    }
  }

  .mbsc-cal-picker {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: .9375em 0;
    transition: all .2s ease-in-out;

    .mbsc-cal-cell {
      width: 33.333333%;
      height: 25%;
      cursor: pointer;
    }
  }

  .mbsc-cal-h {
    visibility: hidden;
    opacity: 0;
  }

  .mbsc-cal-day {
    max-width: 14.285715%; // if only width is used, layout is broken with week numbers displayed
    height: 16.666667%; // IE needs it in fullscreen
    position: relative;
    text-align: center;
    cursor: pointer;
  }

  .mbsc-cal-day-i {
    position: relative;
    height: 100%;
  }

  .mbsc-cal-day-diff .mbsc-cal-day-date {
    opacity: .5;
  }

  .mbsc-cal-week-view .mbsc-cal-day-date,
  .mbsc-cal-day-diff.mbsc-selected .mbsc-cal-day-date {
    opacity: 1;
  }

  .mbsc-cal-cell.mbsc-disabled {
    cursor: not-allowed;

    .mbsc-cal-cell-txt {
      opacity: .2;
    }
  }

  /* Hide outer days */

  .mbsc-cal-hide-diff {
    .mbsc-cal-day-diff {
      cursor: default;

      .mbsc-cal-day-i {
        visibility: hidden;
      }
    }
  }

  /* Week numbers */

  .mbsc-ltr .mbsc-cal-weeks .mbsc-cal-days {
    margin-left: 1.625em;
  }

  .mbsc-rtl .mbsc-cal-weeks .mbsc-cal-days {
    margin-right: 1.625em;
  }

  .mbsc-cal-week-nr {
    width: 1.625em;
    text-align: center;
  }

  /* Multiple month */

  .mbsc-cal-multi {
    .mbsc-cal-hdr {
      display: block;
    }

    .mbsc-cal-btn-w {
      width: auto;
    }

    .mbsc-cal-days,
    .mbsc-cal-slide {
      padding: 0 .5em;
    }
  }

  /* Liquid mode */

  .mbsc-fr-liq {
    .mbsc-cal-c {
      display: block;
    }
  }

  /* Full screen mode */

  .mbsc-calendar.mbsc-fr-liq.mbsc-fr-center {
    .mbsc-fr-overlay {
      display: none;
    }

    .mbsc-fr-w {
      border-radius: 0;
      padding-left: constant(safe-area-inset-left);
      padding-left: env(safe-area-inset-left);
      padding-right: constant(safe-area-inset-right);
      padding-right: env(safe-area-inset-right);
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  /* Tabs */

  .mbsc-cal-tabs-c {
    display: none;
  }

  .mbsc-cal-tabs {
    display: table;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .mbsc-cal-tab {
    position: relative;
    width: 33.33%;
    display: table-cell;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
  }

  .mbsc-cal-tabbed {
    .mbsc-fr-c {
      position: relative;
    }

    .mbsc-w-p {
      transition: opacity .2s ease-in-out;
    }

    .mbsc-sc-whl-gr-c {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      max-width: none;
      height: 100%;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-box-align: center;
      align-items: center;
    }

    .mbsc-cal-c,
    .mbsc-cal-tabs-c {
      display: block;
    }

    /* During positioning */
    .mbsc-fr-pos {
      .mbsc-sc-whl-gr-c {
        width: auto;
      }
    }
  }

  /* Marks */

  .mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: .25em;
  }

  .mbsc-cal-marks {
    position: absolute;
    left: 0;
    right: 0;
    height: .375em;
    margin-top: -.125em;
    overflow: hidden;
    white-space: nowrap;
  }

  .mbsc-cal-mark {
    display: inline-block;
    vertical-align: top;
    width: .25em;
    height: .25em;
    margin: .0625em;
    border-radius: .25em;
  }

  /* Text & icons */

  // .mbsc-cal-has-txt .mbsc-cal-day {
  //     padding-bottom: 1em;

  //     .mbsc-cal-icons {
  //         background: none;
  //     }
  // }

  .mbsc-cal-txt-w {
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }

  .mbsc-ltr .mbsc-cal-txt {
    text-align: left;
  }

  .mbsc-rtl .mbsc-cal-txt {
    text-align: right;
  }

  .mbsc-cal-txt,
  .mbsc-cal-txt-ph,
  .mbsc-cal-txt-more {
    position: relative;
    height: 1.6em;
    line-height: 1.6em;
    overflow: hidden;
    padding: 0 .3em;
    font-size: .625em;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 .1em .2em .1em;
  }

  .mbsc-no-touch .mbsc-cal-txt:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color .2s;
  }

  .mbsc-no-touch .mbsc-cal-txt.mbsc-hover:before {
    background-color: rgba(0, 0, 0, .2);
  }

  .mbsc-no-touch .mbsc-cal-txt-more:hover {
    background-color: rgba(0, 0, 0, .1);
  }

  .mbsc-cal-txt-more {
    text-align: left;
    transition: background-color .2s;
  }

  // .mbsc-cal-txt {
  //     position: absolute;
  //     left: .1em;
  //     right: .1em;
  //     height: 1.6em;
  //     line-height: 1.6em;
  //     overflow: hidden;
  //     padding: 0 .2em;
  //     font-size: .625em;
  //     white-space: nowrap;
  // }

  .mbsc-cal .mbsc-cal-btn {
    position: relative;
    z-index: 1;
  }
}



@include exports("calbase.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-cal-c {
      padding: .5em;
    }

    .mbsc-cal-btn-w {
      font-size: 1.125em;
      line-height: 2.222223em;
    }

    .mbsc-cal .mbsc-cal-btn {
      width: 2.666667em;
      height: 2.666667em;
      line-height: 2.666667em;
      padding: 0;
      border-radius: 2em;
      margin: -.222222em;
    }

    .mbsc-cal-picker {
      border-top: 1px solid transparent;

      .mbsc-cal-cell-i {
        display: inline-block;
        height: 2em;
        padding: 0 1em;
        line-height: 2em;
        border-radius: 2em;
        white-space: nowrap;
      }
    }

    .mbsc-cal-days > div {
      font-size: .6875em;
    }

    .mbsc-cal-day-date {
      display: inline-block;
      width: 2.153847em;
      height: 2.153847em;
      margin: .307692em 0;
      line-height: 2.153847em;
      font-size: .8125em;
      border: 2px solid transparent;
      border-radius: 2em;
    }

    /* Week numbers */
    .mbsc-cal-week-nr {
      width: 2.363637em;
      font-size: .6875em;
    }

    /* Tabs */
    .mbsc-cal-tabs-c {
      line-height: 1.875em;
      padding: .5em .5em 0 .5em;
      text-transform: uppercase;
    }

    .mbsc-cal-tab {
      font-size: .7em;
      padding: 0 1.2em;
    }
  }

  @include mbsc-mobiscroll-calbase(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}





@mixin mbsc-mobiscroll-calendar($theme, $params) {
  @include exports("calendar.#{$theme}.colors") {
    @include mbsc-mobiscroll-calbase($theme, $params);
  }
}





@include exports("calendar.mobiscroll") {
  @include mbsc-mobiscroll-calendar(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// timespan component






@mixin mbsc-mobiscroll-timespan($theme, $params) {
  @include exports("timespan.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}




@include exports("timespan") {
  .mbsc-ts .mbsc-ts-lbl {
    display: none;
  }
}


@include exports("timespan.mobiscroll") {
  @include mbsc-mobiscroll-timespan(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// timer component






@mixin mbsc-mobiscroll-timer($theme, $params) {
  @include exports("timer.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}




@include exports("timer") {
  .mbsc-timer .mbsc-timer-lbl {
    display: none;
  }
}


@include exports("timer.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-timer {
      .mbsc-fr-btn-cont {
        display: table;

        .mbsc-fr-btn-w {
          width: 1px;
          text-align: left;
          display: table-cell;
          float: none;
        }

        .mbsc-fr-btn-w:first-child {
          width: auto;
          text-align: left;
        }

        .mbsc-fr-btn-w .mbsc-fr-btn {
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          text-decoration: none;
        }
      }
    }
  }

  @include mbsc-mobiscroll-timer(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// color component








@mixin mbsc-mobiscroll-page($theme, $params) {
  @include exports("page.#{$theme}.colors") {
    @include mbsc-mobiscroll-common($theme, $params);

    $text: map-get($params, text);
    $accent: map-get($params, accent);
    $background: map-get($params, background);

    .mbsc-#{$theme} {
      &.mbsc-page {
        background: $background;
        color: $text;
      }

      a {
        color: $accent;
      }
    }
  }
}




@include exports("page") {
  [mbsc-page]:not(.mbsc-page) {
    // Prevent FOUC
    visibility: hidden;
  }

  .mbsc-page-ctx {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .mbsc-page {
    min-height: 100%;
    font-size: 16px;
    font-family: FSHackney-Bold, verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mbsc-page,
  .mbsc-page * {
    box-sizing: border-box;
  }

  .mbsc-page:before,
  .mbsc-page:after {
    content: '';
    display: table;
  }


  /* RTL mode */

  .mbsc-rtl {
    direction: rtl;
  }


  /* Typography */

  .mbsc-page h1,
  .mbsc-page h2,
  .mbsc-page h3,
  .mbsc-page h4,
  .mbsc-page h5,
  .mbsc-page h6,
  .mbsc-h1,
  .mbsc-h2,
  .mbsc-h3,
  .mbsc-h4,
  .mbsc-h5,
  .mbsc-h6 {
    margin: 0;
    padding: 0;
    color: inherit;
    font-weight: normal;
    font-family: inherit;
  }

  .mbsc-page p,
  .mbsc-p {
    margin: 1em 0;
    padding: 0;
    line-height: 1.5;
  }

  .mbsc-page a,
  .mbsc-a {
    text-decoration: none;
  }

  .mbsc-page a:hover,
  .mbsc-a:hover {
    text-decoration: underline;
  }

  .mbsc-page a.mbsc-btn:hover,
  .mbsc-a.mbsc-btn:hover {
    text-decoration: none;
  }

  .mbsc-page h1,
  .mbsc-h1 {
    margin: .347826em 0;
    font-size: 2.875em;
  }

  .mbsc-page h2,
  .mbsc-h2 {
    margin: .470588em 0;
    font-size: 2.125em;
  }

  .mbsc-page h3,
  .mbsc-h3 {
    margin: .666666em 0;
    font-size: 1.5em;
  }

  .mbsc-page h4,
  .mbsc-h4 {
    margin: .8em 0;
    font-size: 1.25em;
  }

  .mbsc-page h5,
  .mbsc-h5 {
    margin: 1.066666em 0;
    font-size: .9375em;
  }

  .mbsc-page h6,
  .mbsc-h6 {
    margin: 1.333333em 0;
    font-size: .75em;
  }

  /* Padding, margin */

  .mbsc-padding {
    padding: 1em;
  }

  .mbsc-padding > p:first-child {
    margin-top: 0;
  }

  .mbsc-padding > p:last-child {
    margin-bottom: 0;
  }

  .mbsc-margin {
    margin: 1em 0;
  }

  .mbsc-margin:first-child {
    margin-top: 0;
  }

  .mbsc-margin:last-child {
    margin-bottom: 0;
  }

  /* Lists */

  .mbsc-page ul,
  .mbsc-page ol,
  .mbsc-ul,
  .mbsc-ol {
    padding: 0;
    margin: 1em 0 1em 1.25em;
    line-height: 1.5;
  }

  .mbsc-page ul ul,
  .mbsc-page ol ol,
  .mbsc-ul .mbsc-ul,
  .mbsc-ol .mbsc-ol {
    margin: 0 0 0 1.25em;
  }

  /* Font sizes */

  .mbsc-txt-xs {
    font-size: .625em
  }

  .mbsc-txt-s {
    font-size: .75em;
  }

  .mbsc-txt-m {
    font-size: 1.25em;
  }

  .mbsc-txt-l {
    font-size: 1.5em;
  }

  .mbsc-txt-xl {
    font-size: 2em;
  }

  .mbsc-txt-muted {
    opacity: .6;
  }

  /* Line heights */
  .mbsc-line-height-xs {
    line-height: 1;
  }

  .mbsc-line-height-s {
    line-height: 1.25;
  }

  .mbsc-line-height-m {
    line-height: 1.5;
  }

  .mbsc-line-height-l {
    line-height: 1.75;
  }

  .mbsc-line-height-xl {
    line-height: 2;
  }

  /* Font weights */

  .mbsc-ultra-bold {
    font-weight: 900;
  }

  .mbsc-bold {
    font-weight: bold;
  }

  .mbsc-medium {
    font-weight: 500;
  }

  .mbsc-light {
    font-weight: 300;
  }

  .mbsc-thin {
    font-weight: 100;
  }

  .mbsc-italic {
    font-style: italic;
  }

  /* Text align */

  .mbsc-align-left {
    text-align: left;
  }

  .mbsc-align-right {
    text-align: right;
  }

  .mbsc-align-center {
    text-align: center;
  }

  .mbsc-align-justify {
    text-align: justify;
  }

  /* Float */

  .mbsc-pull-right {
    float: right;
  }

  .mbsc-pull-left {
    float: left;
  }

  /* Image section */

  .mbsc-media-fluid {
    display: block;
    width: 100%;
  }

  .mbsc-img-thumbnail {
    width: 6em;
    height: 6em;
    margin: 1em;
  }

  /* Avatar image */

  .mbsc-avatar {
    width: 2.5em;
    height: 2.5em;
    padding: 0;
    border-radius: 1.25em;
  }

  /* Note */

  .mbsc-note {
    position: relative;
    padding: .75em 1.25em;
    margin: 1em;
    border: 1px solid transparent;
    font-size: .875em;
  }

  @media screen and (max-width: 600px) {
    .mbsc-note {
      text-align: center;
    }
  }
}


@include exports("page.mobiscroll") {
  .mbsc-mobiscroll {

    /* Note */
    .mbsc-note {
      border-radius: .1875em;
    }

    .mbsc-note,
    .mbsc-note-primary {
      color: darken($mbsc-mobiscroll-primary, 30%);
      background-color: lighten($mbsc-mobiscroll-primary, 20%);
    }

    .mbsc-note-secondary {
      color: darken($mbsc-mobiscroll-secondary, 30%);
      background-color: lighten($mbsc-mobiscroll-secondary, 20%);
    }

    .mbsc-note-success {
      color: darken($mbsc-mobiscroll-success, 30%);
      background-color: lighten($mbsc-mobiscroll-success, 20%);
    }

    .mbsc-note-danger {
      color: darken($mbsc-mobiscroll-danger, 30%);
      background-color: lighten($mbsc-mobiscroll-danger, 20%);
    }

    .mbsc-note-warning {
      color: darken($mbsc-mobiscroll-warning, 25%);
      background-color: lighten($mbsc-mobiscroll-warning, 20%);
    }

    .mbsc-note-info {
      color: darken($mbsc-mobiscroll-info, 30%);
      background-color: lighten($mbsc-mobiscroll-info, 20%);
    }

    .mbsc-note-light {
      color: darken($mbsc-mobiscroll-light, 60%);
      background-color: darken($mbsc-mobiscroll-light, 5%);
    }

    .mbsc-note-dark {
      color: darken($mbsc-mobiscroll-dark, 30%);
      background-color: lighten($mbsc-mobiscroll-dark, 40%);
    }
  }

  @include mbsc-mobiscroll-page(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}






@mixin mbsc-mobiscroll-progress($theme, $params) {
  @include exports("progress.#{$theme}.colors") {

    $accent: map-get($params, accent);

    $colors: mbsc-mobiscroll-colors($params);
    $input-icon: map-get($colors, input-icon);
    $btn-disabled: map-get($colors, btn-disabled);

    .mbsc-#{$theme} {
      &.mbsc-progress .mbsc-input-ic {
        color: $input-icon;
      }

      .mbsc-progress-track {
        background: $btn-disabled;
      }

      .mbsc-progress-bar {
        background: $accent;
      }
    }
  }
}




@include exports("progress") {
  .mbsc-progress {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .mbsc-progress progress {
    display: none;
  }

  .mbsc-progress .mbsc-input-wrap {
    position: relative;
    display: block;
  }

  .mbsc-progress .mbsc-input-ic {
    position: absolute;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
  }

  .mbsc-progress-cont {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }

  .mbsc-progress-track {
    position: relative;
    display: block;
    box-sizing: border-box;
  }

  .mbsc-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
  }

  .mbsc-rtl .mbsc-progress-bar {
    left: auto;
    right: 0;
  }

  .mbsc-progress-anim .mbsc-progress-bar {
    transition: width .1s ease-in-out;
  }

  .mbsc-progress-value {
    position: absolute;
    top: 50%;
    width: 3em;
    overflow: hidden;
    margin-top: -.5em;
    line-height: 1em;
  }

  .mbsc-progress-value-right .mbsc-progress-value {
    right: 0;
    text-align: right;
  }

  .mbsc-rtl.mbsc-progress-value-right .mbsc-progress-value {
    right: auto;
    left: 0;
    text-align: left;
  }

  .mbsc-progress-value-left .mbsc-progress-value {
    left: 0;
    text-align: left;
  }

  .mbsc-rtl.mbsc-progress-value-left .mbsc-progress-value {
    left: auto;
    right: 0;
    text-align: right;
  }

  .mbsc-progress-step-label {
    position: absolute;
    top: 1em;
    width: 3.5em;
    font-size: .75em;
    text-align: center;
    overflow: hidden;
  }

  .mbsc-ltr .mbsc-progress-step-label {
    margin-left: -1.75em;
  }

  .mbsc-rtl .mbsc-progress-step-label {
    margin-right: -1.75em;
  }

  /* Inline labels */

  .mbsc-label-inline.mbsc-progress.mbsc-control-w {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    .mbsc-input-wrap {
      -webkit-box-flex: 1;
      -webkit-flex: 1 auto;
      -moz-box-flex: 1;
      -moz-flex: 1 auto;
      -ms-flex: 1 auto;
      flex: 1 auto;
    }

    .mbsc-label {
      position: static;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -moz-box-flex: 0;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      font-size: 1em;
      line-height: 2.125em;
      width: 30%;
      max-width: 12.5em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}


@include exports("progress.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-progress {
      padding: 1em;
    }

    &.mbsc-progress .mbsc-label {
      font-size: .75em;
    }

    &.mbsc-progress .mbsc-input-ic {
      top: .0625em;
      margin: 0;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 2.75em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 2.75em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-left-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-right-ic {
      right: auto;
      left: -.375em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-right-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-left-ic {
      left: auto;
      right: -.375em;
    }

    .mbsc-progress-cont {
      padding: 1em 0;
    }

    .mbsc-progress-track {
      border-radius: 1em;
      height: .125em;
    }

    .mbsc-progress-value {
      width: 3.75em;
      font-size: .875em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap {
      padding-left: 3.5em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap {
      padding-right: 3.5em;
    }

    &.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 5.5em;
    }

    &.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 5.5em;
    }

    &.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value {
      left: 2.25em;
      right: auto;
    }

    &.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value {
      left: auto;
      right: 2.25em;
    }

    .mbsc-progress-primary .mbsc-progress-bar {
      background: $mbsc-mobiscroll-primary;
    }

    .mbsc-progress-secondary .mbsc-progress-bar {
      background: $mbsc-mobiscroll-secondary;
    }

    .mbsc-progress-success .mbsc-progress-bar {
      background: $mbsc-mobiscroll-success;
    }

    .mbsc-progress-danger .mbsc-progress-bar {
      background: $mbsc-mobiscroll-danger;
    }

    .mbsc-progress-warning .mbsc-progress-bar {
      background: $mbsc-mobiscroll-warning;
    }

    .mbsc-progress-info .mbsc-progress-bar {
      background: $mbsc-mobiscroll-info;
    }
  }

  @include mbsc-mobiscroll-progress(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}






@mixin mbsc-mobiscroll-slider($theme, $params) {
  @include exports("slider.#{$theme}.colors") {

    @include mbsc-mobiscroll-page($theme, $params);
    @include mbsc-mobiscroll-progress($theme, $params);

    $background: map-get($params, background);
    $accent: map-get($params, accent);

    $colors: mbsc-mobiscroll-colors($params);
    $tooltip: map-get($colors, tooltip);

    .mbsc-#{$theme} {
      .mbsc-slider-step {
        background: $background;
      }

      .mbsc-slider-handle {
        background: $accent;
      }

      .mbsc-slider-tooltip {
        color: $tooltip;
        background-color: $accent;
      }
    }
  }
}





@include exports("slider") {
  .mbsc-progress.mbsc-slider input {
    display: none;
  }

  .mbsc-ltr .mbsc-progress-anim .mbsc-slider-handle-cont {
    transition: left .1s ease-in-out;
  }

  .mbsc-rtl .mbsc-progress-anim .mbsc-slider-handle-cont {
    transition: right .1s ease-in-out;
  }

  .mbsc-slider-handle-cont {
    position: absolute;
    width: 2em;
    height: 2em;
    top: .0625em;
    margin-top: -1em;
    cursor: pointer;
  }

  .mbsc-ltr .mbsc-slider-handle-cont {
    right: -1em;
  }

  .mbsc-rtl .mbsc-slider-handle-cont {
    left: -1em;
  }

  .mbsc-ltr .mbsc-slider-handle-cont.mbsc-slider-handle-left {
    left: 0;
  }

  .mbsc-rtl .mbsc-slider-handle-cont.mbsc-slider-handle-left {
    right: 0;
  }

  .mbsc-ltr .mbsc-progress-track .mbsc-slider-handle-cont {
    margin-left: -1em;
  }

  .mbsc-rtl .mbsc-progress-track .mbsc-slider-handle-cont {
    margin-right: -1em;
  }

  .mbsc-slider-handle {
    position: absolute;
    top: .125em;
    right: 50%;
    margin: -.5em -.5em 0 0;
    z-index: 2;
  }

  .mbsc-slider-handle-cont .mbsc-slider-handle {
    box-sizing: content-box;
  }

  .mbsc-slider .mbsc-slider-handle:focus,
  .mbsc-slider .mbsc-active .mbsc-slider-handle {
    outline: 0;
    z-index: 15;
  }

  .mbsc-slider .mbsc-handle-curr {
    z-index: 16;
  }

  .mbsc-slider-tooltip {
    position: absolute;
    right: 50%;
    opacity: 0;
    color: #000;
  }

  .mbsc-slider-step {
    position: absolute;
    top: 0;
    width: .125em;
    height: 100%;
    margin-left: -.0625em;
  }
}


@include exports("slider.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-slider .mbsc-input-wrap {
      padding: 0 .5em;
    }

    .mbsc-slider-handle {
      top: 50%;
      width: 1em;
      height: 1em;
      border-radius: 1.125em;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: -webkit-transform .2s ease-in-out;
      transition: transform .2s ease-in-out;
    }

    .mbsc-slider-handle:focus,
    .mbsc-active .mbsc-slider-handle {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }

    &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-handle {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    .mbsc-slider-tooltip {
      top: -1.91666em;
      width: 2.33333333em;
      height: 2.33333333em;
      border-radius: 2em;
      margin-right: -1.1666em;
      line-height: 2.33333333em;
      text-align: center;
      font-size: .75em;
      z-index: 5;
      -webkit-transform: scale(.4) translate3d(0, 5em, 0);
      transform: scale(.4) translate3d(0, 5em, 0);
      -webkit-transition: -webkit-transform .2s ease-in-out, opacity .2s ease-in-out;
      transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    }

    .mbsc-slider-handle:focus ~ .mbsc-slider-tooltip,
    .mbsc-active .mbsc-slider-tooltip {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1);
      transform: translate3d(0, 0, 0) scale(1);
    }

    &.mbsc-slider-has-tooltip .mbsc-slider-handle:focus,
    &.mbsc-slider-has-tooltip .mbsc-active .mbsc-slider-handle {
      -webkit-transform: scale(.5);
      transform: scale(.5);
    }

    &.mbsc-slider-primary {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-slider-secondary {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-slider-success {

      .mbsc-slider-tooltip,
      .mbsc-slider-handlem .mbsc-progress-bar {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-slider-danger {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-slider-warning {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-slider-info {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-info;
      }
    }
  }

  @include mbsc-mobiscroll-slider(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}






@mixin mbsc-mobiscroll-color($theme, $params) {
  @include exports("color.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);
    @include mbsc-mobiscroll-slider($theme, $params);

    $accent: map-get($params, accent);

    $colors: mbsc-mobiscroll-colors($params);
    $input-icon: map-get($colors, input-icon);
    $color-input: map-get($colors, color-input);

    .mbsc-#{$theme} {

      .mbsc-color-active,
      &.mbsc-no-touch .mbsc-color-item-c:hover {
        box-shadow: inset 0 0 0 .1875em $accent;
      }

      &.mbsc-input .mbsc-color-input {
        border-bottom: 1px solid $input-icon;
        color: $color-input;
      }
    }
  }
}




@include exports("color") {
  .mbsc-color {
    font-size: 16px;
  }

  .mbsc-color-scroll-cont {
    padding: .125em 0 .1875em 0;
    overflow: hidden;
    box-sizing: content-box;
  }

  .mbsc-color-cont {
    display: table;
    table-layout: fixed;
    margin: 0 auto;
  }

  .mbsc-color-item-c {
    position: relative;
    display: table-cell;
    padding: .18755em;
    max-width: 4.25em;
    max-height: 4.25em;
  }

  .mbsc-color-clear-item,
  .mbsc-color-item {
    position: relative;
    min-width: 3.5em;
    min-height: 3.5em;
    margin: .1875em;
    cursor: pointer;
  }

  .mbsc-color-clear-item {
    background: rgba(223, 222, 222, .6);
  }

  .mbsc-color-clear-cross {
    position: absolute;
    height: 100%;
    width: 2px;
    top: 0;
    left: 50%;
    margin-left: -1px;
    background: red;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .mbsc-color-row {
    padding: 0 .5em;
  }

  .mbsc-color.mbsc-fr-bubble .mbsc-fr-w,
  .mbsc-color.mbsc-fr-center .mbsc-fr-w {
    max-width: 286px;
    min-width: 280px;
  }

  .mbsc-color .mbsc-fr-c {
    padding: .25em 0;
  }


  /* vertical scroll override */

  .mbsc-color-vertical .mbsc-color-cont {
    width: 100%;
    height: 100%;
    white-space: normal;
    text-align: start;
  }

  .mbsc-color-vertical .mbsc-color-item-c {
    display: inline-block;
    vertical-align: top;
    text-align: center;
  }


  /* slider overrides */

  .mbsc-color .mbsc-color-slider-cont.mbsc-progress {
    padding: 0;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-progress-cont {
    padding: 1.5em 0;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-progress-track {
    height: .25em;
    border: 0;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-slider-handle-cont {
    top: .125em;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-slider-step {
    display: none;
  }


  /* checkmark */

  .mbsc-color-clear-item:before,
  .mbsc-color-item:before {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    color: #f7f7f7;
    display: none;
    font-size: 1.5em;
  }

  .mbsc-color-clear-item:before {
    display: block;
  }

  .mbsc-color-clear-item,
  .mbsc-color-input-item-close:before,
  .mbsc-color-selected::before {
    display: block;
    text-shadow: 0 0 .125em #000;
  }


  /* refine mode multiple select */

  .mbsc-color-refine-preview {
    display: table;
    padding: 0 .25em;
  }

  .mbsc-color-preview-cont {
    border-top: 1px solid #ccc;
    overflow: hidden;
    padding-top: .25em;
  }

  .mbsc-color-preview-item-c {
    display: table-cell;
  }

  .mbsc-color-preview-item {
    min-width: 1.875em;
    width: 1.875em;
    height: 1.875em;
    margin: .25em;
    cursor: pointer;
    background: transparent;
    box-sizing: border-box;
  }

  .mbsc-color-preview-item-empty {
    border: 1px dashed;
    border-color: #bbb;
  }


  /* input style */

  .mbsc-color-input {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #aaa;
    min-height: 2.125em;
    width: 100%;
    padding: .125em;
    box-sizing: border-box;
  }

  .mbsc-color-input:empty:not(:focus):before {
    content: attr(data-placeholder);
    position: absolute;
    top: 50%;
    overflow: hidden;
    margin-top: -1em;
    opacity: .6;
    font-size: 1em;
    line-height: 2em;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .mbsc-label-floating .mbsc-color-input:empty:not(:focus):before {
    opacity: 0;
  }

  .mbsc-label-floating-active .mbsc-color-input:empty:not(:focus):before {
    opacity: 1;
  }

  .mbsc-color-input-item {
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    position: relative;
    margin: .125em;
    height: 1.5em;
    width: 4.0625em;
    vertical-align: middle;
  }

  .mbsc-color-input-hdn {
    position: absolute;
    height: 1px !important;
    width: 1px !important;
    margin: 0 !important;
    padding: 0 !important;
    left: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .mbsc-color-input-item-close {
    position: absolute;
    top: 10%;
    right: 0;
    height: 80%;
    width: 1.75em;
    cursor: pointer;
    color: #fff;
    border-left: 1px solid #fff;
    text-align: center;
  }

  .mbsc-color-input-item-close:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    margin: -.75em 0 0 -.75em;
  }

  .mbsc-input .mbsc-color-input {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
  }


  /* JQuery Mobile */

  .ui-input-text .mbsc-color-input {
    border-color: transparent;
  }
}


@include exports("color.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-color-preview-item {
      border-radius: 1em;
    }

    .mbsc-color-clear-item,
    .mbsc-color-item {
      border-radius: 1.75em;
    }

    .mbsc-color-active,
    &.mbsc-no-touch .mbsc-color-item-c:hover {
      border-radius: 3em;
    }

    .mbsc-color-preview-cont {
      padding: .25em 0 0 .25em;
    }

    &.mbsc-input .mbsc-color-input {
      height: auto;
      min-height: 2.125em;
      padding: 0 0 1px 0;
      background: transparent;
      font-size: 1em;
    }

    &.mbsc-input .mbsc-color-input-item {
      border-radius: .125em;
    }

    &.mbsc-input-box.mbsc-label-stacked .mbsc-color-input:empty:not(:focus):before {
      margin-top: -.375em;
    }
  }

  @include mbsc-mobiscroll-color(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// range component






@mixin mbsc-mobiscroll-range($theme, $params) {
  @include exports("range.#{$theme}.colors") {

    @include mbsc-mobiscroll-calbase($theme, $params);

    $background: map-get($params, background);
    $text: map-get($params, text);
    $accent: map-get($params, accent);

    .mbsc-#{$theme} {
      .mbsc-range-btn {
        border: 1px solid $accent;
      }

      &.mbsc-range {
        .mbsc-range-btn-t .mbsc-range-btn.mbsc-selected {
          background: $accent;
          color: $background;
        }

        .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
          background: none;
          border-color: transparent;
          color: $text;
        }

        .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
          background: $accent;
          border-color: $accent;
          color: $background;
        }

        .mbsc-cal-day.mbsc-selected:after {
          background: rgba($accent, .3);
        }
      }
    }
  }
}




@include exports("range") {

  .mbsc-range-btn,
  .mbsc-range-btn-t,
  .mbsc-range-btn-c {
    box-sizing: border-box;
  }

  .mbsc-range-btn-t {
    display: table;
    width: 100%;
  }

  .mbsc-range-btn-c {
    display: table-cell;
    width: 50%;
  }

  .mbsc-range-btn {
    text-decoration: none;
    text-align: center;
    display: block;
    cursor: pointer;
  }

  .mbsc-cal-hide-diff .mbsc-cal-day-diff:after {
    display: none;
  }
}


@include exports("range.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-range-btn-t {
      padding: .5em .25em 0 .25em;
    }

    .mbsc-range-btn {
      margin: 0 .3333em;
      padding: .3333em;
      font-size: .75em;
      line-height: 1.666667em;
    }

    &.mbsc-range {
      .mbsc-fr-w {
        padding: 0;
      }

      .mbsc-fr-hdr {
        padding-top: .6666em;
      }

      .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
        background: none;
        border-color: transparent;
      }

      .mbsc-cal-day.mbsc-selected:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: .25em;
        left: 0;
        right: 0;
        height: 2em;
      }

      .mbsc-ltr .mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-sel-end:after {
        left: 50%;
        margin-left: -1em;
      }

      .mbsc-ltr .mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-sel-start:after {
        right: 50%;
        margin-right: -1em;
      }

      .mbsc-ltr .mbsc-cal-sel-start:after,
      .mbsc-ltr .mbsc-cal-day:first-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-first:after,
      .mbsc-rtl .mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-day:last-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-last:after {
        border-top-left-radius: 2em;
        border-bottom-left-radius: 2em;
      }

      .mbsc-ltr .mbsc-cal-sel-end:after,
      .mbsc-ltr .mbsc-cal-day:last-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-last:after,
      .mbsc-rtl .mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-day:first-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-first:after {
        border-top-right-radius: 2em;
        border-bottom-right-radius: 2em;
      }
    }
  }

  @include mbsc-mobiscroll-range(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// scroller component



// listview component





@mixin mbsc-mobiscroll-listview-base($theme, $params) {
  @include exports("listview-base.#{$theme}.colors") {

    $background: map-get($params, background);
    $text: map-get($params, text);
    $accent: map-get($params, accent);

    $colors: mbsc-mobiscroll-colors($params);
    $switch: map-get($colors, switch);

    .mbsc-#{$theme} {
      .mbsc-lv {
        background: $switch;
      }

      .mbsc-lv-item {
        background: $background;
        color: $text;
      }

      .mbsc-lv-gr-title {
        background: $accent;
        color: $background;
      }

      &.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
      .mbsc-lv-item-active.mbsc-lv-item-actionable:before {
        background: rgba($accent, .15);
      }
    }
  }
}



@include exports("listview-base") {
  .mbsc-lv-cont {
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -ms-touch-action: pan-y;
    user-select: none;
    touch-action: pan-y;
    font-size: 16px;
    font-family: FSHackney-Bold, verdana, sans-serif;
    padding-top: 1px;
  }

  /* List */

  .mbsc-lv-cont .mbsc-lv {
    list-style: none;
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  .mbsc-lv {
    display: none;
    position: relative;
  }

  .mbsc-lv-v {
    display: block;
  }

  /* Group title */

  .mbsc-lv-gr-title {
    position: relative;
    z-index: 3;
    display: block;
  }

  /* Items */

  .mbsc-lv .mbsc-lv-item {
    position: relative;
    z-index: 2;
    list-style: none;
    display: block;
    box-sizing: border-box;
  }

  .mbsc-lv-item-actionable {
    cursor: pointer;
  }

  .mbsc-lv .mbsc-lv-item.mbsc-input {
    margin: -1px 0 0 0;
  }

  .mbsc-lv-item-actionable:before,
  .mbsc-lv-item.mbsc-selected:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transition: background-color .2s ease-out;
  }
}


@include exports("listview-base.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-lv-cont {
      font-family: FSHackney-Bold, verdana, sans-serif;
      padding-top: 1px;
    }

    .mbsc-lv-item {
      padding: 1.25em;
      font-weight: normal;
    }

    .mbsc-lv-gr-title {
      padding: 0 1.25em;
      font-size: .75em;
      text-transform: uppercase;
      line-height: 2em;
      margin-top: -1px;
    }
  }

  @include mbsc-mobiscroll-listview-base(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}





@mixin mbsc-mobiscroll-listview($theme, $params) {
  @include exports("listview.#{$theme}.colors") {

    @include mbsc-mobiscroll-listview-base($theme, $params);
    @include mbsc-mobiscroll-common($theme, $params);

    $background: map-get($params, background);
    $text: map-get($params, text);
    $accent: map-get($params, accent);

    $colors: mbsc-mobiscroll-colors($params);
    $lv-item: map-get($colors, lv-item);
    $white-text: map-get($colors, white-text);

    .mbsc-#{$theme} {
      &.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
        background: $lv-item;
      }

      .mbsc-lv-item-hl:before {
        background: rgba($accent, .15);
      }

      .mbsc-lv-ic-m {
        color: $white-text;
      }

      .mbsc-lv-arr {
        color: $accent;
      }

      .mbsc-lv-handle-bar {
        background: $accent;
      }

      .mbsc-lv-item.mbsc-lv-item-dragging {
        background: $accent;
        color: $background;
      }

      .mbsc-lv-item-dragging .mbsc-lv-handle-bar {
        background: $background;
      }

      .mbsc-lv-item-dragging .mbsc-lv-arr {
        color: $background;
      }

      .mbsc-lv-loading .mbsc-ic {
        color: $text;
      }

      &.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
        background: rgba($accent, .3);
      }
    }
  }
}






@include exports("listview") {
  /* Animations */

  @-webkit-keyframes mbsc-lv-spin {
    from {
      -webkit-transform: rotate(180deg);
      opacity: 0;
    }

    to {
      -webkit-transform: rotate(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes mbsc-lv-remove-right {
    from {}

    /* Needed by Android 2.3 */
    to {
      -webkit-transform: translateX(100%);
    }
  }

  @-webkit-keyframes mbsc-lv-remove-left {
    from {}

    /* Needed by Android 2.3 */
    to {
      -webkit-transform: translateX(-100%);
    }
  }

  @-webkit-keyframes mbsc-lv-add-right {
    from {
      -webkit-transform: translateX(100%);
    }

    to {
      -webkit-transform: translateX(0);
    }
  }

  @-webkit-keyframes mbsc-lv-add-left {
    from {
      -webkit-transform: translateX(-100%);
    }

    to {
      -webkit-transform: translateX(0);
    }
  }

  @-webkit-keyframes mbsc-lv-pop-in {
    from {
      -webkit-transform: scale(0);
      opacity: 0;
    }

    to {
      -webkit-transform: scale(1);
      opacity: 1;
    }
  }

  @-webkit-keyframes mbsc-lv-pop-out {
    from {
      -webkit-transform: scale(1);
      opacity: 1;
    }

    to {
      -webkit-transform: scale(0);
      opacity: 0;
    }
  }

  @-webkit-keyframes mbsc-lv-collapse {
    from {
      padding: 0;
      border: 0;
    }

    to {
      padding: 0;
      border: 0;
      height: 0;
    }
  }

  @-webkit-keyframes mbsc-lv-expand {
    from {
      padding: 0;
      border: 0;
      height: 0;
    }

    to {
      padding: 0;
      border: 0;
    }
  }

  @-webkit-keyframes mbsc-lv-fill {
    from {
      -webkit-transform: scale(1, 0);
    }

    to {
      -webkit-transform: scale(1, 1);
    }
  }

  @keyframes mbsc-lv-spin {
    from {
      transform: rotate(180deg);
      opacity: 0;
    }

    to {
      transform: rotate(0);
      opacity: 1;
    }
  }

  @keyframes mbsc-lv-remove-right {
    to {
      transform: translateX(100%);
    }
  }

  @keyframes mbsc-lv-remove-left {
    to {
      transform: translateX(-100%);
    }
  }

  @keyframes mbsc-lv-add-right {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes mbsc-lv-add-left {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes mbsc-lv-pop-in {
    from {
      transform: scale(0);
      opacity: 0;
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes mbsc-lv-pop-out {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(0);
      opacity: 0;
    }
  }

  @keyframes mbsc-lv-collapse {
    from {
      padding: 0;
      border: 0;
    }

    to {
      padding: 0;
      border: 0;
      height: 0;
    }
  }

  @keyframes mbsc-lv-expand {
    from {
      padding: 0;
      border: 0;
      height: 0;
    }

    to {
      padding: 0;
      border: 0;
    }
  }

  @keyframes mbsc-lv-fill {
    from {
      transform: scale(1, 0);
    }

    to {
      transform: scale(1, 1);
    }
  }

  /* Loading animation */

  @keyframes mbsc-lv-rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes mbsc-lv-rotating {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Backface visibility */

  .mbsc-lv-stage-c-v,
  .mbsc-lv-item,
  .mbsc-lv-gr-title,
  .mbsc-lv-ic-text {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .mbsc-lv,
  .mbsc-lv-fill-item {
    -webkit-backface-visibility: hidden;
  }

  .mbsc-rtl {
    direction: rtl;
  }

  /* List */

  .mbsc-lv.mbsc-lv-dummy {
    display: block;
    margin-top: -1px;
    -webkit-backface-visibility: visible;
  }

  /* Fixed header */

  .mbsc-lv-fixed-header {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    list-style: none;
    width: 100%;
    z-index: 10;
  }

  .mbsc-lv-fixed-header-ctx {
    position: absolute;
  }

  /* Items */

  .mbsc-lv .mbsc-lv-item.mbsc-lv-item-swiping {
    z-index: 1;
    overflow: visible;
  }

  .mbsc-lv-stage-c {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
  }

  .mbsc-lv-stage-c-v {
    display: block;
  }

  .mbsc-lv-item-new-left {
    -webkit-transform: translateX(-100%);
  }

  .mbsc-lv-item-new-right {
    -webkit-transform: translateX(100%);
  }

  .mbsc-lv-item-remove-right {
    -webkit-animation: mbsc-lv-remove-right 125ms;
    -webkit-animation-delay: 1ms;
    /* Needed for iOS8 to prevent flicker */
    animation: mbsc-lv-remove-right 125ms;
  }

  .mbsc-lv-item-remove-left {
    -webkit-animation: mbsc-lv-remove-left 125ms;
    -webkit-animation-delay: 1ms;
    /* Needed for iOS8 to prevent flicker */
    animation: mbsc-lv-remove-left 125ms;
  }

  .mbsc-lv-item-add-right {
    -webkit-animation: mbsc-lv-add-right 125ms;
    animation: mbsc-lv-add-right 125ms;
  }

  .mbsc-lv-item-add-left {
    -webkit-animation: mbsc-lv-add-left 125ms;
    animation: mbsc-lv-add-left 125ms;
  }

  .mbsc-lv-item-pop-in {
    -webkit-animation: mbsc-lv-pop-in 200ms;
    animation: mbsc-lv-pop-in 200ms;
  }

  .mbsc-lv-item-pop-out {
    -webkit-animation: mbsc-lv-pop-out 200ms;
    animation: mbsc-lv-pop-out 200ms;
  }

  .mbsc-lv-item-collapse {
    visibility: hidden;
    -webkit-animation: mbsc-lv-collapse 125ms;
    animation: mbsc-lv-collapse 125ms;
  }

  .mbsc-lv-item-expand {
    visibility: hidden;
    -webkit-animation: mbsc-lv-expand 125ms;
    animation: mbsc-lv-expand 125ms;
  }

  .mbsc-lv .mbsc-lv-item.mbsc-lv-item-undo {
    position: absolute;
    left: 0;
    right: 0;
  }

  /* Reorder */

  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-item-dragging {
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    opacity: .9;
    box-shadow: 0 0 0.625em rgba(0, 0, 0, .5);
    cursor: pointer;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .mbsc-lv-item-dragging .mbsc-lv-handle-c {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .mbsc-lv .mbsc-lv-item.mbsc-lv-ph {
    padding: 0;
    border: 0;
    visibility: hidden;
  }

  .mbsc-lv-fill-item {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .1;
  }

  /* Sort handle */

  .mbsc-lv-handle-c {
    position: absolute;
    z-index: 4;
    top: 0;
    height: 100%;
    width: 2.5em;
    -ms-touch-action: none;
    touch-action: none;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .mbsc-lv-item-h-right {
    right: 0;
  }

  .mbsc-lv-item-h-left {
    left: 0;
  }

  .mbsc-lv-cont.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item {
    padding-left: 2.5em;
  }

  .mbsc-lv-cont.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item {
    padding-right: 2.5em;
  }

  .mbsc-lv-handle-bar-c {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -7px;
  }

  .mbsc-lv-handle-bar {
    position: relative;
    height: 2px;
    margin: 2px .625em;
    background: #888;
  }

  /* Icon and text */

  .mbsc-lv-ic,
  .mbsc-lv-ic-m {
    top: 0;
    text-align: center;
    text-shadow: none;
    font-size: .75em;
  }

  /* Stage icon */

  .mbsc-lv-ic-s {
    position: absolute;
    top: 50%;
    margin-top: -0.9375em;
    width: 2.8em;
    height: 1.875em;
    line-height: 1.875em;
    font-size: 1.25em;
  }

  /* Stage icon container */

  .mbsc-lv-ic-c {
    position: absolute;
    top: 0;
    height: 100%;
    line-height: 1.875em;
    color: #fff;
    cursor: pointer;
  }

  /* Stage text */

  .mbsc-lv-ic-text {
    text-shadow: none;
    font-size: 1em;
  }

  /* Empty icon */

  .mbsc-ic-none {
    border: 0;
  }

  .mbsc-ic-none:before {
    content: "0";
    font-size: 0;
    color: rgba(0, 0, 0, 0);
  }

  /* Stage icon animation */

  .mbsc-lv-ic-anim .mbsc-lv-ic-s {
    opacity: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .mbsc-lv-ic-anim .mbsc-lv-ic-a {
    opacity: 1;
    -webkit-animation: mbsc-lv-spin 200ms;
    -webkit-transform: rotate(0);
    animation: mbsc-lv-spin 200ms;
    transform: rotate(0);
  }

  .mbsc-lv-ic-anim .mbsc-lv-ic-v {
    opacity: 1;
    -webkit-animation: none;
    -webkit-transform: rotate(0);
    animation: none;
    transform: rotate(0);
  }

  /* Stage icon and text left and right positioning */

  .mbsc-lv-ic-left {
    left: 0;
  }

  .mbsc-lv-ic-move-left {
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .mbsc-lv-ic-right .mbsc-lv-ic,
  .mbsc-lv-ic-move-left .mbsc-lv-ic {
    right: 0;
  }

  .mbsc-lv-ic-right .mbsc-lv-ic-text,
  .mbsc-lv-ic-move-left .mbsc-lv-ic-text {
    padding: 0 3.5em 0 0.625em;
  }

  .mbsc-lv-ic-right {
    right: 0;
  }

  .mbsc-lv-ic-move-right {
    right: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  .mbsc-lv-ic-left .mbsc-lv-ic,
  .mbsc-lv-ic-move-right .mbsc-lv-ic {
    left: 0;
  }

  .mbsc-lv-ic-left .mbsc-lv-ic-text,
  .mbsc-lv-ic-move-right .mbsc-lv-ic-text {
    padding: 0 0.625em 0 3.5em;
  }

  .mbsc-lv-ic-c .mbsc-lv-ic-only {
    width: 0;
    padding: 0 0 0 3.5em;
  }

  .mbsc-lv-ic-c .mbsc-lv-ic-text-only {
    padding: 0 0.625em;
  }

  /* Multiple icons */

  .mbsc-lv-multi-c {
    height: 100%;
  }

  .mbsc-lv-multi {
    display: none;
    width: 90%;
    height: 100%;
    table-layout: fixed;
  }

  .mbsc-lv-multi-ic-right {
    float: right;
  }

  .mbsc-lv-multi-ic-left {
    float: left;
  }

  .mbsc-lv-right .mbsc-lv-multi-ic-right {
    display: table;
  }

  .mbsc-lv-left .mbsc-lv-multi-ic-left {
    display: table;
  }

  .mbsc-lv-ic-m {
    display: table-cell;
    vertical-align: middle;
    padding: 0 .25em;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-lv-ic-m:before {
    display: block;
    padding: .25em 0;
    font-size: 1.333334em;
  }

  .mbsc-lv-ic-disabled {
    opacity: .5;
  }

  /* Hierarchy slide containers */

  .mbsc-lv-sl-c {
    position: relative;
    -webkit-animation-fill-mode: forwards;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .mbsc-lv-sl-r {
    -webkit-animation: mbsc-lv-remove-left 300ms;
    animation: mbsc-lv-remove-left 300ms;
  }

  .mbsc-lv-sl-l {
    -webkit-animation: mbsc-lv-remove-right 300ms;
    animation: mbsc-lv-remove-right 300ms;
  }

  .mbsc-lv-sl-l .mbsc-lv-sl-curr,
  .mbsc-lv-sl-r .mbsc-lv-sl-curr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .mbsc-lv-sl-r .mbsc-lv-sl-new {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  .mbsc-lv-sl-l .mbsc-lv-sl-new {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  /* Hierarchy arrows */

  .mbsc-lv-arr {
    position: absolute;
    top: 50%;
    width: 2em;
    height: 2em;
    font-size: 1em;
    line-height: 2em;
    margin-top: -1em;
  }

  .mbsc-lv .mbsc-lv-back {
    z-index: 3;
  }

  .mbsc-ltr .mbsc-lv-item.mbsc-lv-parent {
    padding-right: 2.25em;
  }

  .mbsc-ltr .mbsc-lv-item.mbsc-lv-back {
    padding-left: 2.25em;
  }

  .mbsc-ltr .mbsc-lv-parent .mbsc-lv-arr {
    right: 0;
  }

  .mbsc-ltr.mbsc-lv-handle-right .mbsc-lv-parent .mbsc-lv-arr {
    right: 40px;
  }

  .mbsc-ltr .mbsc-lv-back .mbsc-lv-arr {
    left: 0;
  }

  .mbsc-rtl .mbsc-lv-arr {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  .mbsc-rtl .mbsc-lv-item.mbsc-lv-parent {
    padding-left: 2.25em;
  }

  .mbsc-rtl .mbsc-lv-item.mbsc-lv-back {
    padding-right: 2.25em;
  }

  .mbsc-rtl .mbsc-lv-parent .mbsc-lv-arr {
    left: 0;
  }

  .mbsc-rtl.mbsc-lv-handle-left .mbsc-lv-parent .mbsc-lv-arr {
    left: 40px;
  }

  .mbsc-rtl .mbsc-lv-back .mbsc-lv-arr {
    right: 0;
  }

  /* Text enhance */

  .mbsc-lv .mbsc-lv-txt {
    margin: 0;
    font-weight: normal;
  }

  .mbsc-lv h1.mbsc-lv-txt {
    font-size: 2em;
  }

  .mbsc-lv h2.mbsc-lv-txt {
    font-size: 1.5em;
  }

  .mbsc-lv h3.mbsc-lv-txt {
    font-size: 1.125em;
  }

  .mbsc-lv h4.mbsc-lv-txt {
    font-size: 1em;
  }

  .mbsc-lv h5.mbsc-lv-txt {
    font-size: .875em;
  }

  .mbsc-lv h6.mbsc-lv-txt {
    font-size: .625em;
  }

  .mbsc-lv p.mbsc-lv-txt {
    font-size: .75em;
  }

  /* Image and text enhance */

  .mbsc-lv-img {
    position: absolute;
    max-height: 2.5em;
    max-width: 2.5em;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 5em;
  }

  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-img-right {
    padding-right: 5em;
  }

  .mbsc-lv-img-left .mbsc-lv-img {
    left: 2.5em;
  }

  .mbsc-lv-img-right .mbsc-lv-img {
    right: 0;
  }

  .mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 6.25em;
  }

  .mbsc-lv-handle-left .mbsc-lv-img-left .mbsc-lv-img {
    left: 3.75em;
  }

  .mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item.mbsc-lv-img-right {
    padding-right: 6.25em;
  }

  .mbsc-lv-handle-right .mbsc-lv-img-right .mbsc-lv-img {
    right: 1.25em;
  }

  /* Icon and text enhance */

  .mbsc-lv-item-ic {
    position: absolute;
    top: 50%;
    margin-top: -1em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
  }

  .mbsc-lv-cont .mbsc-lv .mbsc-lv-item.mbsc-lv-item-ic-left {
    padding-left: 4em;
  }

  .mbsc-lv-cont .mbsc-lv .mbsc-lv-item.mbsc-lv-item-ic-right {
    padding-right: 4em;
  }

  .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
    left: 1em;
  }

  .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
    right: 1em;
  }

  .mbsc-lv-handle-left.mbsc-lv-cont .mbsc-lv .mbsc-lv-item-ic-left {
    padding-left: 5.5em;
  }

  .mbsc-lv-handle-left .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
    left: 2.5em;
  }

  .mbsc-lv-handle-right.mbsc-lv-cont .mbsc-lv .mbsc-lv-item-ic-right {
    padding-right: 5.5em;
  }

  .mbsc-lv-handle-right .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
    right: 2.5em;
  }

  /* Loading animation */

  .mbsc-lv-loading {
    text-align: center;
    opacity: 0;
    height: 0;

    .mbsc-ic {
      font-size: 1.375em;
    }

    .mbsc-ic:before {
      display: inline-block;
      -webkit-animation: mbsc-lv-rotating .6s linear infinite;
      animation: mbsc-lv-rotating .6s linear infinite;
    }
  }

  .mbsc-show-lv-loading.mbsc-lv-loading {
    opacity: 1;
    height: auto;
    padding: 1em;
  }
}


@include exports("listview.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-lv-item.mbsc-lv-item-dragging {
      margin: 0;
      box-shadow: none;
    }

    &.mbsc-lv-handle-left .mbsc-lv-item.mbsc-checkbox.mbsc-rtl {
      padding-left: 4.25em;

      .mbsc-checkbox-box {
        left: 2.5em;
      }
    }
  }

  @include mbsc-mobiscroll-listview(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// number component






@mixin mbsc-mobiscroll-number($theme, $params) {
  @include exports("number.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}





@include exports("number.mobiscroll") {
  @include mbsc-mobiscroll-number(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// numpad component






@mixin mbsc-mobiscroll-numpad($theme, $params) {
  @include exports("numpad.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);

    $accent: map-get($params, accent);

    .mbsc-#{$theme} {
      .mbsc-np-del {
        color: $accent;
      }
    }
  }
}




@include exports("numpad") {
  .mbsc-np-hdr {
    position: relative;
  }

  .mbsc-np-dsp {
    direction: ltr;
    padding: .3125em 1.75em .3125em .5em;
    text-align: left;
    font-size: 2em;
    line-height: normal;
  }

  .mbsc-np-sup {
    display: inline-block;
    font-size: .375em;
    vertical-align: top;
  }

  .mbsc-np-del {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3.5em;
    text-align: center;
  }

  .mbsc-np-del:before {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -.5em;
    font-size: 2em;
    line-height: 1;
  }

  .mbsc-np-ph {
    display: inline-block;
    min-width: .5625em;
    text-align: center;
    vertical-align: top;
  }

  .mbsc-np-tbl-c {
    min-width: 15em;
    /*display: inline-block;*/
  }

  .mbsc-fr-top .mbsc-np-tbl-c,
  .mbsc-fr-bottom .mbsc-np-tbl-c {
    display: block;
  }

  .mbsc-np-tbl {
    direction: ltr;
    display: table;
    width: 100%;
    font-size: 1.375em;
  }

  .mbsc-np-row {
    display: table-row;
  }

  .mbsc-np-btn {
    position: relative;
    z-index: 0;
    width: 33.3333333333%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    height: 3em;
    -webkit-user-select: none;
  }

  .mbsc-np-btn.mbsc-active {
    opacity: 1;
  }


  /* Timespan */

  .mbsc-np-time {
    text-transform: uppercase;
  }

  .mbsc-np-ts-h,
  .mbsc-np-ts-m {
    font-weight: bold;
  }
}


@include exports("numpad.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-np-tbl-c {
      padding: .5em;
    }

    .mbsc-np-btn.mbsc-disabled {
      opacity: .5;
    }
  }

  @include mbsc-mobiscroll-numpad(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// forms component





@include exports("collapsible") {
  .mbsc-collapsible.mbsc-form-group {
    margin: 0;
  }

  .mbsc-collapsible-header {
    position: relative;
    padding-right: 3em;
  }

  .mbsc-collapsible .mbsc-collapsible-icon {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 50%;
    right: 1em;
    margin-top: -.5em;
    line-height: 1em;
    font-size: 1em;
    transition: transform .125s ease-out;
  }

  .mbsc-collapsible .mbsc-collapsible-content {
    overflow: hidden;
    transition: height .125s ease;
    box-sizing: content-box;
  }

  .mbsc-collapsible:not(.mbsc-collapsible-open) .mbsc-collapsible-content {
    height: 0;
    padding-bottom: 0;
  }

  .mbsc-collapsible-open > .mbsc-collapsible-header .mbsc-collapsible-icon {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }

  /* Collapsible rtl */

  .mbsc-rtl {

    .mbsc-collapsible.mbsc-form-group,
    .mbsc-collapsible.mbsc-card {

      .mbsc-collapsible-header,
      .mbsc-collapsible-header.mbsc-form-group-title {
        padding-right: 1em;
        padding-left: 3em;

        .mbsc-collapsible-icon {
          right: auto;
          left: 1em;
        }
      }
    }
  }
}


@include exports("collapsible.mobiscroll") {
  .mbsc-mobiscroll .mbsc-collapsible {
    .mbsc-form-group-title {
      padding: 1em;
    }

    .mbsc-collapsible-header {
      padding-right: 3em;
    }
  }
}










@mixin mbsc-mobiscroll-rating($theme, $params) {
  @include exports("rating.#{$theme}.colors") {

    @include mbsc-mobiscroll-progress($theme, $params);

    $accent: map-get($params, accent);

    .mbsc-#{$theme}.mbsc-rating {
      .mbsc-progress-track {
        color: $accent;
      }
    }
  }
}




@include exports("rating") {
  .mbsc-rating {
    &.mbsc-progress .mbsc-progress-cont {
      display: inline-block;
      width: auto;
      padding: 0 .5em;
      margin: 0 -.681818em;
      cursor: pointer;
    }

    .mbsc-progress-cont .mbsc-progress-track {
      height: auto;
      background: none;
    }

    .mbsc-ic {
      font-size: 1.375em;
      margin: 0 0.181818em;
    }

    &.mbsc-progress input {
      display: none;
    }

    &.mbsc-progress .mbsc-progress-cont .mbsc-slider-handle {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      top: 0;
      left: 0;
      right: 0;
      background: none;
      box-shadow: none;
      border: 0;
      -webkit-transform: none;
      transform: none;
    }

    .mbsc-progress-cont .mbsc-progress-bar {
      background: none;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}


@include exports("rating.mobiscroll") {
  .mbsc-mobiscroll.mbsc-rating {
    .mbsc-label {
      padding-bottom: .5em;
    }

    input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    &.mbsc-rating-primary .mbsc-progress-track {
      color: $mbsc-mobiscroll-primary;
    }

    &.mbsc-rating-secondary .mbsc-progress-track {
      color: $mbsc-mobiscroll-secondary;
    }

    &.mbsc-rating-success .mbsc-progress-track {
      color: $mbsc-mobiscroll-success;
    }

    &.mbsc-rating-danger .mbsc-progress-track {
      color: $mbsc-mobiscroll-danger;
    }

    &.mbsc-rating-warning .mbsc-progress-track {
      color: $mbsc-mobiscroll-warning;
    }

    &.mbsc-rating-info .mbsc-progress-track {
      color: $mbsc-mobiscroll-info;
    }
  }

  @include mbsc-mobiscroll-rating(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}








@mixin mbsc-mobiscroll-popup($theme, $params) {
  @include exports("popup.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);
  }
}




@include exports("popup") {

  .mbsc-wdg .mbsc-wdg-c {
    position: relative;
    z-index: 0;
    font-size: 14px;
    white-space: normal;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .mbsc-wdg .mbsc-ltr .mbsc-wdg-c {
    text-align: left;
  }

  .mbsc-wdg .mbsc-rtl .mbsc-wdg-c {
    text-align: right;
  }

  .mbsc-wdg .mbsc-wdg-c.mbsc-w-p {
    padding: 1em;
  }

  .mbsc-no-padding.mbsc-wdg .mbsc-wdg-c.mbsc-w-p {
    padding: 0;
  }
}


@include exports("popup.mobiscroll") {
  @include mbsc-mobiscroll-popup(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}





@mixin mbsc-mobiscroll-notifications($theme, $params) {
  @include exports("notifications.#{$theme}.colors") {

    @include mbsc-mobiscroll-popup($theme, $params);

    $background: map-get($params, background);

    $colors: mbsc-mobiscroll-colors($params);
    $notification: map-get($colors, notification);
    $white-text: map-get($colors, white-text);

    .mbsc-#{$theme} {

      &.mbsc-snackbar .mbsc-fr-w,
      &.mbsc-toast .mbsc-toast-msg {
        background: $notification;
        color: $white-text;
      }

      /* Snackbar and Toast color presets */
      &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
      &.mbsc-toast.mbsc-primary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
      &.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
      &.mbsc-toast.mbsc-success .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
      &.mbsc-toast.mbsc-danger .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
      &.mbsc-toast.mbsc-warning .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
      &.mbsc-toast.mbsc-info .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
        color: $background;
      }
    }
  }
}




@include exports("notifications") {
  /* Toast, snackbar */

  .mbsc-toast .mbsc-fr-persp,
  .mbsc-snackbar .mbsc-fr-persp {
    pointer-events: none;
  }

  .mbsc-toast .mbsc-fr-overlay,
  .mbsc-snackbar .mbsc-fr-overlay {
    display: none;
  }

  .mbsc-toast.mbsc-fr .mbsc-fr-persp .mbsc-fr-popup,
  .mbsc-snackbar.mbsc-fr .mbsc-fr-persp .mbsc-fr-popup {
    border: 0;
    line-height: 1.428572;
  }

  /* Snackbar */

  .mbsc-snackbar.mbsc-fr .mbsc-fr-w {
    min-width: 18em;
    max-width: 36em;
    margin: 0 auto;
    pointer-events: auto;
    box-shadow: none;
  }

  .mbsc-snackbar-cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mbsc-snackbar-msg {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 1em;
  }

  .mbsc-snackbar .mbsc-snackbar-cont .mbsc-snackbar-btn {
    margin: -1em 0;
    padding: 1em;
    line-height: 1.428572;
    font-size: 1em;
    font-weight: 400;
  }

  .mbsc-snackbar.mbsc-no-touch.mbsc-fr .mbsc-fr-c .mbsc-btn-flat:not(:disabled):hover {
    background: none;
    box-shadow: none;
  }


  /* Toast */

  .mbsc-toast.mbsc-fr .mbsc-fr-w {
    background: none;
    box-shadow: none;
  }

  .mbsc-toast.mbsc-fr .mbsc-fr-c.mbsc-wdg-c {
    text-align: center;
    padding-bottom: 4em;
  }

  .mbsc-toast .mbsc-toast-msg {
    display: inline-block;
    min-width: 10em;
    max-width: 50em;
    padding: .857143em 2em;
  }

  /* Alert, confirm, prompt */

  .mbsc-alert.mbsc-fr .mbsc-fr-c.mbsc-wdg-c {
    padding: 1.714286em;
    max-width: 20em;
  }

  .mbsc-alert .mbsc-fr-c .mbsc-input {
    padding: 0;
    margin: 0;
  }

  .mbsc-alert.mbsc-fr h2 {
    margin: 0 0 1em 0;
    padding: 0;
    font-size: 1.428572em;
    font-weight: bold;
  }

  .mbsc-alert.mbsc-fr p {
    margin: 1em 0;
    padding: 0;
    font-size: 1em;
  }
}

@include exports("notifications.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-toast .mbsc-toast-msg {
      opacity: .9;
      border-radius: .5em;
    }

    /* Snackbar and Toast color presets */
    &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
    &.mbsc-toast.mbsc-primary .mbsc-toast-msg {
      background: $mbsc-mobiscroll-primary;
    }

    &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
    &.mbsc-toast.mbsc-secondary .mbsc-toast-msg {
      background: $mbsc-mobiscroll-secondary;
    }

    &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
    &.mbsc-toast.mbsc-success .mbsc-toast-msg {
      background: $mbsc-mobiscroll-success;
    }

    &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
    &.mbsc-toast.mbsc-danger .mbsc-toast-msg {
      background: $mbsc-mobiscroll-danger;
    }

    &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
    &.mbsc-toast.mbsc-warning .mbsc-toast-msg {
      background: $mbsc-mobiscroll-warning;
    }

    &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
    &.mbsc-toast.mbsc-info .mbsc-toast-msg {
      background: $mbsc-mobiscroll-info;
    }
  }

  @include mbsc-mobiscroll-notifications(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}









@mixin mbsc-mobiscroll-forms($theme, $params) {
  @include exports("forms.#{$theme}.colors") {

    @include mbsc-mobiscroll-page($theme, $params);
    @include mbsc-mobiscroll-input($theme, $params);
    @include mbsc-mobiscroll-slider($theme, $params);
    @include mbsc-mobiscroll-rating($theme, $params);
    @include mbsc-mobiscroll-notifications($theme, $params);

    $background: map-get($params, background);
    $text: map-get($params, text);
    $accent: map-get($params, accent);

    $colors: mbsc-mobiscroll-colors($params);
    $input-disabled: map-get($colors, input-disabled);
    $btn-light: map-get($colors, btn-light);
    $btn-disabled: map-get($colors, btn-disabled);
    $switch: map-get($colors, switch);
    $checked: map-get($colors, checked);
    $handle: map-get($colors, handle);
    $mbsc-mobiscroll-dark-text: map-get($colors, dark-text);
    $white-text: map-get($colors, light);
    $error: map-get($colors, error);
    $checkbox: map-get($colors, checkbox);

    .mbsc-#{$theme} {

      &.mbsc-form {
        background: $background;
        color: $text;
      }

      &.mbsc-form *::-moz-selection {
        color: $white-text;
        background: $accent;
      }

      &.mbsc-form *::selection {
        color: $white-text;
        background: $accent;
      }

      .mbsc-divider,
      .mbsc-form-group-title {
        background: $background;
        color: $accent;
      }

      .mbsc-err-msg,
      .mbsc-err .mbsc-label {
        color: $error;
      }

      /* Form grid */
      .mbsc-checkbox-box {
        background: $accent;
      }

      .mbsc-checkbox-box:after {
        border-bottom: .125em solid $checkbox;
        border-left: .125em solid $checkbox;
      }

      &.mbsc-checkbox input:disabled+.mbsc-checkbox-box {
        background: $input-disabled;
      }

      /* Radio */
      .mbsc-radio-box {
        border: .125em solid $accent;
      }

      .mbsc-radio-box:after {
        background: $accent;
      }

      &.mbsc-radio input:checked+.mbsc-radio-box {
        background: transparent;
      }

      &.mbsc-radio input:disabled+.mbsc-radio-box {
        border-color: $input-disabled;
      }

      &.mbsc-radio input:disabled+.mbsc-radio-box:after {
        background: $input-disabled;
      }

      /* Buttons */
      &.mbsc-btn {
        background: $accent;
        color: $btn-light;
      }

      &.mbsc-btn:disabled {
        background: $btn-disabled;
      }

      &.mbsc-no-touch.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
        background: rgba($background, .1);
      }

      &.mbsc-btn-flat {
        background: transparent;
        color: $accent;
        border-color: transparent;
      }

      &.mbsc-btn-flat.mbsc-btn.mbsc-active {
        background: rgba($accent, .3)
      }

      &.mbsc-btn-flat:disabled {
        color: $switch;
        background: transparent;
      }

      &.mbsc-btn-light.mbsc-btn {
        color: $mbsc-mobiscroll-dark-text;
      }

      /* Outline buttons */
      &.mbsc-btn-outline.mbsc-btn {
        border: 1px solid $accent;
        color: $accent;

        &.mbsc-active {
          background: $accent;
          color: $background;
        }

        &.mbsc-btn-primary.mbsc-active,
        &.mbsc-btn-secondary.mbsc-active,
        &.mbsc-btn-success.mbsc-active,
        &.mbsc-btn-danger.mbsc-active,
        &.mbsc-btn-warning.mbsc-active,
        &.mbsc-btn-info.mbsc-active,
        &.mbsc-btn-dark.mbsc-active {
          color: $background;
        }
      }

      &.mbsc-btn.mbsc-btn-outline:disabled {
        color: $btn-disabled;
        border-color: $btn-disabled;
        background: transparent;
      }

      /* Switch */
      .mbsc-switch-track {
        background: $btn-disabled;
      }

      .mbsc-switch-handle {
        background: $switch;
      }

      &.mbsc-switch input:checked+.mbsc-switch-track {
        background: $checked;
      }

      &.mbsc-switch input:checked+.mbsc-switch-track .mbsc-switch-handle {
        background: $accent;
      }

      &.mbsc-switch input:disabled+.mbsc-switch-track {
        background: $input-disabled;
      }

      &.mbsc-switch input:disabled+.mbsc-switch-track .mbsc-switch-handle {
        background: $handle;
      }

      /* Stepper and Segmented */
      .mbsc-segmented-content {
        border: .142857em solid $accent;
        color: $accent;
      }

      &.mbsc-stepper input {
        color: $text;
      }

      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input:checked+.mbsc-segmented-content {
        background: $accent;
        color: $background;
      }

      &.mbsc-segmented .mbsc-segmented-item input.mbsc-active+.mbsc-segmented-content {
        background: rgba($accent, .3);
      }

      &.mbsc-segmented input:disabled~.mbsc-segmented-item .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input:disabled+.mbsc-segmented-content {
        color: $input-disabled;
        border-color: $input-disabled;
      }

      .mbsc-stepper input:disabled {
        color: $input-disabled;
        -webkit-text-fill-color: $input-disabled;
      }

      &.mbsc-segmented input:disabled:checked+.mbsc-segmented-content {
        border-color: $btn-disabled;
        background: $btn-disabled;
        color: $btn-light;
      }

      &.mbsc-stepper .mbsc-active.mbsc-disabled .mbsc-segmented-content {
        background: transparent;
        color: $input-disabled;
      }

      &.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: rgba($accent, .1)
      }
    }
  }
}










@include exports("forms") {
  /* Forms */

  [mbsc-form]:not(.mbsc-form) {
    // Prevent FOUC
    visibility: hidden;
  }

  .mbsc-form,
  .mbsc-control-w,
  .mbsc-btn,
  .mbsc-segmented {
    font-size: 16px;
    font-family: FSHackney-Bold, verdana, sans-serif;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .mbsc-form:before,
  .mbsc-form:after {
    content: '';
    display: table;
  }

  .mbsc-form-group {
    margin: 1.5em 0;
  }

  .mbsc-form-group-inset {
    margin: 2em 1.5em;
  }

  .mbsc-form-group,
  .mbsc-form-group-inset {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  /* No background inside widget */

  .mbsc-wdg .mbsc-w-p .mbsc-form {
    background: none;
  }

  /* Other mobiscroll components inline */

  .mbsc-input .mbsc-fr-inline .mbsc-fr-popup {
    display: block;
  }

  .mbsc-err-msg {
    display: block;
    font-size: .75em;
  }

  .mbsc-checkbox,
  .mbsc-switch,
  .mbsc-radio,
  .mbsc-stepper-cont {
    line-height: 1.25em;
  }

  .mbsc-checkbox,
  .mbsc-switch,
  .mbsc-btn,
  .mbsc-radio,
  .mbsc-segmented,
  .mbsc-stepper-cont {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .mbsc-checkbox input,
  .mbsc-switch input,
  .mbsc-radio input,
  .mbsc-segmented input,
  .mbsc-btn {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .mbsc-checkbox input,
  .mbsc-switch input,
  .mbsc-radio input,
  .mbsc-select select,
  .mbsc-segmented input {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0;
    margin: 0;
  }

  .mbsc-btn,
  .mbsc-checkbox-box,
  .mbsc-checkbox-box:after,
  .mbsc-radio-box,
  .mbsc-radio-box:after,
  .mbsc-switch-track,
  .mbsc-segmented,
  .mbsc-progress progress,
  .mbsc-stepper-cont {
    box-sizing: border-box;
  }

  .mbsc-segmented .mbsc-segmented-content,
  .mbsc-btn-flat .mbsc-btn-ic {
    box-sizing: content-box;
  }

  .mbsc-desc {
    display: block;
    font-size: .75em;
    opacity: .6;
  }

  label.mbsc-input,
  .mbsc-label {
    margin: 0;
    display: block;
    font-weight: normal;
  }

  .mbsc-control-w {
    // Override bootstrap defaults
    max-width: none;
    margin: 0;
    font-size: 1em;
    font-weight: normal;
  }

  /* Textarea */

  .mbsc-input textarea {
    resize: none;
    overflow: hidden;
    line-height: 1.5em;
  }

  .mbsc-input .mbsc-textarea-scroll {
    overflow: auto;
  }

  /* Select */

  .mbsc-select select,
  .mbsc-select input {
    cursor: pointer;
  }

  .mbsc-select select option {
    color: initial;
  }

  .mbsc-select-ic {
    display: none;
  }

  /* Checkbox */

  .mbsc-checkbox {
    padding: .5em;
  }

  .mbsc-checkbox-box {
    position: absolute;
    top: 50%;
    display: block;
    width: 1.375em;
    height: 1.375em;
  }

  .mbsc-checkbox-box:after {
    content: '';
    position: absolute;
    display: block;
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .mbsc-checkbox input:checked + .mbsc-checkbox-box:after {
    opacity: 1;
  }

  /* Radio */

  .mbsc-radio {
    padding: .5em;
  }

  .mbsc-radio-box {
    position: absolute;
    top: 50%;
    display: block;
    width: 1.25em;
    height: 1.25em;
    border-radius: 1.25em;
  }

  .mbsc-radio-box:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: .625em;
    height: .625em;
    margin-top: -.3125em;
    margin-left: -.3125em;
    border-radius: .625em;
    opacity: 0;
  }

  .mbsc-radio input:checked + .mbsc-radio-box:after {
    opacity: 1;
  }

  /* Switch */

  .mbsc-switch {
    padding: .5em;
  }

  .mbsc-switch-track {
    position: absolute;
    top: 50%;
    display: block;
    width: 3.375em;
    height: 1.625em;
    transition: background-color .2s ease-in-out, border .2s ease-in-out;
    z-index: 4;
  }

  .mbsc-switch .mbsc-switch-track .mbsc-progress-track {
    height: 100%;
    background: none;
  }

  .mbsc-switch .mbsc-switch-track .mbsc-slider-handle-cont {
    top: 50%;
  }

  .mbsc-switch-handle {
    position: absolute;
    display: block;
  }

  .mbsc-switch-txt-off,
  .mbsc-switch-txt-on {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    font-size: .625em;
    text-align: center;
    line-height: 2em;
  }

  /* Segmented control & Stepper */

  .mbsc-segmented {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .mbsc-segmented .mbsc-segmented-item {
    margin: 0;
    display: table-cell;
    position: relative;
    vertical-align: top;
    text-align: center;
    font-size: 1em;
  }

  .mbsc-segmented-content {
    position: relative;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: .875em;
    font-weight: normal;
    z-index: 2;
  }

  // Angular + Bootstrap compatibility
  .mbsc-segmented-item label {
    display: block;
    margin: 0;
  }

  .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-disabled .mbsc-segmented-content,
  .mbsc-segmented input:disabled + .mbsc-segmented-content {
    z-index: 0;
  }

  .mbsc-stepper {
    position: absolute;
    display: block;
    width: auto;
    right: 1em;
    top: 50%;
  }

  .mbsc-rtl .mbsc-stepper {
    right: auto;
    left: 1em;
  }

  .mbsc-stepper-cont .mbsc-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .mbsc-segmented-item:focus {
    outline: 0;
  }

  .mbsc-stepper input {
    position: absolute;
    left: 4.142857em;
    width: 4.142857em;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    font-size: .875em;
    text-align: center;
    opacity: 1;
    z-index: 4;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
  }

  .mbsc-stepper input::-webkit-outer-spin-button,
  .mbsc-stepper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .mbsc-ltr.mbsc-stepper-val-right .mbsc-stepper input {
    left: auto;
    right: 0;
  }

  .mbsc-rtl.mbsc-stepper-val-right .mbsc-stepper input {
    right: auto;
    left: 0;
  }

  .mbsc-ltr.mbsc-stepper-val-left .mbsc-stepper input {
    left: 0;
  }

  .mbsc-rtl.mbsc-stepper-val-left .mbsc-stepper input {
    right: 0;
  }

  .mbsc-stepper .mbsc-segmented-item {
    width: 3.625em;
  }

  .mbsc-stepper-cont.mbsc-stepper-val-left .mbsc-stepper .mbsc-segmented-item:nth-child(2) .mbsc-segmented-content,
  .mbsc-stepper-cont.mbsc-stepper-val-right .mbsc-stepper .mbsc-segmented-item:last-child .mbsc-segmented-content {
    /* Strong rule is needed to override disabled styling */
    border: 0;
    background: transparent;
  }

  .mbsc-control-w.mbsc-stepper-cont .mbsc-stepper {
    padding: 0;
  }

  .mbsc-segmented-item .mbsc-control,
  .mbsc-stepper .mbsc-segmented-content {
    cursor: pointer;
  }

  .mbsc-disabled .mbsc-segmented-content,
  .mbsc-segmented input:disabled,
  .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content {
    cursor: not-allowed;
  }

  /* Buttons */

  .mbsc-btn {
    position: relative;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 1em;
    font-family: FSHackney-Bold, verdana, sans-serif;
    cursor: pointer;
  }

  .mbsc-btn:disabled {
    cursor: not-allowed;

    * {
      /* Prevents click event firing for the Angular mbsc-button component, when disabled */
      pointer-events: none;
    }
  }

  .mbsc-btn:focus {
    outline: 0;
  }

  .mbsc-btn-ic {
    line-height: 1;
  }

  .mbsc-btn-group,
  .mbsc-btn-group-block,
  .mbsc-btn-group-justified {
    // Prevent margin collision
    border: 1px solid transparent;
  }

  .mbsc-btn-group-block .mbsc-btn,
  .mbsc-btn-block {
    display: block;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mbsc-btn-group-justified,
  .mbsc-btn-group-justified mbsc-button {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mbsc-btn-group-justified .mbsc-btn,
  .mbsc-btn-group-justified mbsc-button {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }

  input:disabled + span .mbsc-slider-handle-cont {
    cursor: not-allowed;
  }

  /* Form in grid */

  .mbsc-form .mbsc-form-grid .mbsc-form-group-title {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}


@include exports("forms.mobiscroll") {

  .mbsc-mobiscroll {

    .mbsc-divider,
    .mbsc-form-group-title {
      padding: .5em 1em;
    }

    .mbsc-err-msg {
      position: absolute;
      padding-top: .333334em;
      font-size: .75em;
    }

    /* Form grid */

    .mbsc-form-grid {
      margin-top: .75em;
      margin-bottom: .75em;
    }

    .mbsc-form-grid .mbsc-input-box,
    .mbsc-form-grid .mbsc-input-outline {
      margin: .75em 1em;
    }

    /* Checkbox, switch, radio */
    &.mbsc-checkbox .mbsc-err-msg,
    &.mbsc-switch .mbsc-err-msg,
    &.mbsc-radio .mbsc-err-msg {
      padding-top: 0;
    }

    /* Checkbox */
    &.mbsc-ltr.mbsc-checkbox {
      padding: 1em 3.125em 1em 1em;
    }

    &.mbsc-rtl.mbsc-checkbox {
      padding: 1em 1em 1em 3.125em;
    }

    .mbsc-checkbox-box {
      margin-top: -.5625em;
      width: 1.125em;
      height: 1.125em;
      right: 1em;
    }

    &.mbsc-rtl .mbsc-checkbox-box {
      right: auto;
      left: 1em;
    }

    .mbsc-checkbox-box:after {
      top: 0.25em;
      left: .185em;
      width: .8125em;
      height: .4375em;
    }

    /* Checkbox color presets */
    &.mbsc-checkbox-primary .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-primary;
    }

    &.mbsc-checkbox-secondary .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-secondary;
    }

    &.mbsc-checkbox-success .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-success;
    }

    &.mbsc-checkbox-danger .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-danger;
    }

    &.mbsc-checkbox-warning .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-warning;
    }

    &.mbsc-checkbox-info .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-info;
    }

    /* Radio */
    &.mbsc-radio {
      padding: 1em 3.125em 1em 1em;
    }

    &.mbsc-rtl.mbsc-radio {
      padding: 1em 1em 1em 3.125em;
    }

    .mbsc-radio-box {
      right: 1em;
      width: 1.125em;
      height: 1.125em;
      margin-top: -.5625em;
      background: transparent;
    }

    &.mbsc-rtl .mbsc-radio-box {
      right: auto;
      left: 1em;
    }

    .mbsc-radio-box:after {
      width: .5em;
      height: .5em;
      margin-top: -.25em;
      margin-left: -.25em;
      border-radius: .625em;
    }

    &.mbsc-radio input:checked + .mbsc-radio-box {
      background: transparent;
    }

    /* Radio color presets */
    &.mbsc-radio-primary {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-primary;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-radio-secondary {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-secondary;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-radio-success {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-success;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-radio-danger {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-danger;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-radio-warning {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-warning;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-radio-info {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-info;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-info;
      }
    }

    /* Checkbox, Switch, Radio */
    &.mbsc-checkbox input:disabled ~ .mbsc-label,
    &.mbsc-checkbox input:disabled ~ .mbsc-desc,
    &.mbsc-radio input:disabled ~ .mbsc-label,
    &.mbsc-radio input:disabled ~ .mbsc-desc,
    &.mbsc-switch input:disabled ~ .mbsc-label,
    &.mbsc-switch input:disabled ~ .mbsc-desc {
      opacity: .4;
    }

    .mbsc-checkbox-box:after,
    .mbsc-radio-box:after {
      opacity: 1;
      -webkit-transform: scale(0) rotate(-45deg);
      transform: scale(0) rotate(-45deg);
      transition: transform .1s ease-out;
    }

    &.mbsc-checkbox input:checked + .mbsc-checkbox-box:after,
    &.mbsc-radio input:checked + .mbsc-radio-box:after {
      opacity: 1;
      -webkit-transform: scale(1) rotate(-45deg);
      transform: scale(1) rotate(-45deg);
    }

    /* Buttons */
    &.mbsc-btn {
      margin: .5em;
      padding: .6875em;
      font-size: 1em;
      text-transform: uppercase;
    }

    .mbsc-btn-ic {
      padding-right: .6875em;
    }

    .mbsc-btn-icon-only .mbsc-btn-ic {
      padding: 0 .5em;
    }

    &.mbsc-no-touch.mbsc-btn:not(:disabled):not(.mbsc-active):hover {
      opacity: .8;
    }

    &.mbsc-btn.mbsc-active {
      opacity: .6;
    }

    &.mbsc-no-touch.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
      opacity: 1;
    }

    &.mbsc-btn-flat {
      background: transparent;
      border-color: transparent;
    }

    &.mbsc-btn-flat.mbsc-btn.mbsc-active {
      opacity: 1;
    }

    &.mbsc-btn-flat:disabled {
      background: transparent;
    }

    /* Button color presets */
    &.mbsc-btn-primary.mbsc-btn {
      background: $mbsc-mobiscroll-primary;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-primary;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-primary, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-primary, .3);
        }
      }
    }

    &.mbsc-btn-secondary.mbsc-btn {
      background: $mbsc-mobiscroll-secondary;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-secondary;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-secondary, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-secondary, .3);
        }
      }
    }

    &.mbsc-btn-success.mbsc-btn {
      background: $mbsc-mobiscroll-success;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-success;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-success, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-success, .3);
        }
      }
    }

    &.mbsc-btn-danger.mbsc-btn {
      background: $mbsc-mobiscroll-danger;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-danger;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-danger, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-danger, .3);
        }
      }
    }

    &.mbsc-btn-warning.mbsc-btn {
      background: $mbsc-mobiscroll-warning;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-warning;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-warning, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-warning, .3);
        }
      }
    }

    &.mbsc-btn-info.mbsc-btn {
      background: $mbsc-mobiscroll-info;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-info;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-info, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-info, .3);
        }
      }
    }

    &.mbsc-btn-light.mbsc-btn {
      background: $mbsc-mobiscroll-light;

      &.mbsc-btn-flat {
        color: darken($mbsc-mobiscroll-light, 25%);

        &:not(:disabled):not(.mbsc-active):hover {
          color: darken($mbsc-mobiscroll-light, 25%);
          background: rgba(darken($mbsc-mobiscroll-light, 25%), .2);
        }

        &.mbsc-active {
          background: rgba(darken($mbsc-mobiscroll-light, 25%), .3);
        }
      }
    }

    &.mbsc-btn-dark.mbsc-btn {
      background: $mbsc-mobiscroll-dark;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-dark;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-dark, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-dark, .3);
        }
      }
    }

    &.mbsc-btn-flat.mbsc-btn,
    &.mbsc-btn-outline.mbsc-btn {
      background: transparent;
    }

    .mbsc-btn-group,
    .mbsc-btn-group-justified {
      margin: .5em;
    }

    .mbsc-btn-group-block {
      margin: .5em 1em;
    }

    /* Outline buttons */
    &.mbsc-btn-outline.mbsc-btn.mbsc-active {
      opacity: 1;
    }

    /* Outline buttons */
    &.mbsc-btn-outline.mbsc-btn {
      &.mbsc-btn-primary {
        border-color: $mbsc-mobiscroll-primary;
        color: $mbsc-mobiscroll-primary;

        &.mbsc-active {
          background: $mbsc-mobiscroll-primary;
        }
      }

      &.mbsc-btn-secondary {
        border-color: $mbsc-mobiscroll-secondary;
        color: $mbsc-mobiscroll-secondary;

        &.mbsc-active {
          background: $mbsc-mobiscroll-secondary;
        }
      }

      &.mbsc-btn-success {
        border-color: $mbsc-mobiscroll-success;
        color: $mbsc-mobiscroll-success;

        &.mbsc-active {
          background: $mbsc-mobiscroll-success;
        }
      }

      &.mbsc-btn-danger {
        border-color: $mbsc-mobiscroll-danger;
        color: $mbsc-mobiscroll-danger;

        &.mbsc-active {
          background: $mbsc-mobiscroll-danger;
        }
      }

      &.mbsc-btn-warning {
        border-color: $mbsc-mobiscroll-warning;
        color: $mbsc-mobiscroll-warning;

        &.mbsc-active {
          background: $mbsc-mobiscroll-warning;
        }
      }

      &.mbsc-btn-info {
        border-color: $mbsc-mobiscroll-info;
        color: $mbsc-mobiscroll-info;

        &.mbsc-active {
          background: $mbsc-mobiscroll-info;
        }
      }

      &.mbsc-btn-light {
        border-color: darken($mbsc-mobiscroll-light, 25%);
        color: darken($mbsc-mobiscroll-light, 25%);

        &.mbsc-active {
          background: darken($mbsc-mobiscroll-light, 25%);
          color: $mbsc-mobiscroll-light;
        }
      }

      &.mbsc-btn-dark {
        border-color: $mbsc-mobiscroll-dark;
        color: $mbsc-mobiscroll-dark;

        &.mbsc-active {
          background: $mbsc-mobiscroll-dark;
        }
      }
    }

    /* Switch */
    &.mbsc-ltr.mbsc-switch {
      padding: 1em 4.375em 1em 1em;
    }

    &.mbsc-rtl.mbsc-switch {
      padding: 1em 1em 1em 4.375em;
    }

    .mbsc-switch-track {
      right: 1em;
      width: 2.375em;
      height: .875em;
      padding: 0 .75em;
      margin-top: -.4375em;
      border-radius: 1.25em;
    }

    &.mbsc-rtl .mbsc-switch-track {
      right: auto;
      left: 1em;
    }

    .mbsc-switch-handle {
      top: .25em;
      left: .25em;
      margin: 0;
      width: 1.5em;
      height: 1.5em;
      border-radius: 1.25em;
    }

    &.mbsc-rtl .mbsc-switch-handle {
      right: .25em;
    }

    .mbsc-active .mbsc-switch-handle {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    &.mbsc-switch input:disabled + .mbsc-switch-track {
      opacity: .7;
    }

    /* Switch color presets */
    &.mbsc-switch.mbsc-switch-primary input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-primary, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-switch.mbsc-switch-secondary input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-secondary, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-switch.mbsc-switch-success input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-success, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-switch.mbsc-switch-danger input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-danger, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-switch.mbsc-switch-warning input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-warning, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-switch.mbsc-switch-info input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-info, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-info;
      }
    }

    /* Stepper and Segmented */
    &.mbsc-segmented {
      padding: .5em 1em;
    }

    .mbsc-segmented-content {
      height: 2.28571428em;
      margin: 0 -.071428em;
      line-height: 2.28575em;
      padding: 0 .285714em;
      text-transform: uppercase;
    }

    &.mbsc-ltr.mbsc-stepper-cont {
      padding: 1.75em 12.875em 1.75em 1em;
    }

    &.mbsc-rtl.mbsc-stepper-cont {
      padding: 1.75em 1em 1.75em 12.875em;
    }

    .mbsc-stepper {
      margin-top: -1.125em;
    }

    &.mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
    &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
    &.mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
      background: transparent;
    }

    /* Segmented color presets */
    .mbsc-segmented-primary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-primary;
        color: $mbsc-mobiscroll-primary;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-primary;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-primary, .35)
      }
    }

    .mbsc-segmented-secondary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-secondary;
        color: $mbsc-mobiscroll-secondary;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-secondary;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-secondary, .35)
      }
    }

    .mbsc-segmented-success {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-success;
        color: $mbsc-mobiscroll-success;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-success;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-success, .35)
      }
    }

    .mbsc-segmented-danger {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-danger;
        color: $mbsc-mobiscroll-danger;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-danger;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-danger, .35)
      }
    }

    .mbsc-segmented-warning {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-warning;
        color: $mbsc-mobiscroll-warning;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-warning;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-warning, .35)
      }
    }

    .mbsc-segmented-info {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-info;
        color: $mbsc-mobiscroll-info;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-info;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-info, .35)
      }
    }

    /* Stepper color presets */
    &.mbsc-stepper-primary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-primary;
        color: $mbsc-mobiscroll-primary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-stepper-secondary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-secondary;
        color: $mbsc-mobiscroll-secondary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-stepper-success {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-success;
        color: $mbsc-mobiscroll-success;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-stepper-danger {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-danger;
        color: $mbsc-mobiscroll-danger;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-stepper-warning {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-warning;
        color: $mbsc-mobiscroll-warning;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-stepper-info {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-info;
        color: $mbsc-mobiscroll-info;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-info;
      }
    }

    &.mbsc-no-touch {

      /* Segmented hover color styles */
      .mbsc-segmented-primary {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-primary, .35)
        }
      }

      .mbsc-segmented-secondary {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-secondary, .35)
        }
      }

      .mbsc-segmented-success {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-success, .35)
        }
      }

      .mbsc-segmented-danger {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-danger, .35)
        }
      }

      .mbsc-segmented-warning {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-warning, .35)
        }
      }

      .mbsc-segmented-info {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-info, .35)
        }
      }

      /* Stepper hover color styles */
      &.mbsc-stepper-primary {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-primary, .35)
        }
      }

      &.mbsc-stepper-secondary {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-secondary, .35)
        }
      }

      &.mbsc-stepper-success {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-success, .35)
        }
      }

      &.mbsc-stepper-danger {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-danger, .35)
        }
      }

      &.mbsc-stepper-warning {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-warning, .35)
        }
      }

      &.mbsc-stepper-info {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-info, .35)
        }
      }
    }
  }

  @include mbsc-mobiscroll-forms(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// eventcalendar component








@mixin mbsc-mobiscroll-eventcalendar($theme, $params) {
  @include exports("eventcalendar.#{$theme}.colors") {

    @include mbsc-mobiscroll-calbase($theme, $params);
    @include mbsc-mobiscroll-listview-base($theme, $params);
    @include mbsc-mobiscroll-common($theme, $params);

    $background: map-get($params, background);
    $accent: map-get($params, accent);
    $text: map-get($params, text);

    $colors: mbsc-mobiscroll-colors($params);
    $btn-disabled: map-get($colors, btn-disabled);
    $event-border: map-get($colors, event-border);

    .mbsc-#{$theme} {
      &.mbsc-cal-events-popup {
        .mbsc-fr-w {
          border: .375em solid $event-border;
        }

        .mbsc-fr-arr {
          background: $event-border;
        }
      }

      .mbsc-cal-event-color {
        background: $accent;
      }

      .mbsc-cal-event-time,
      .mbsc-cal-event-dur {
        color: $accent;
      }

      /* Event listing */
      .mbsc-event-list .mbsc-lv-gr-title {
        background: $background;
        color: $accent;
      }

      .mbsc-event-color {
        background: rgba($text, .3);
      }

      /* Desktop grid mode */
      &.mbsc-ev-cal.mbsc-fr-md {
        .mbsc-cal-table {
          border-top: 1px solid $btn-disabled;
        }

        .mbsc-cal-cell {
          border-left: 1px solid $btn-disabled;
          border-bottom: 1px solid $btn-disabled;
        }
      }
    }
  }
}





@include exports("eventcalendar") {
  .mbsc-cal-events-popup {
    .mbsc-fr-persp {
      pointer-events: none;
    }

    .mbsc-fr-popup {
      pointer-events: auto;
    }
  }

  .mbsc-cal-events {
    box-sizing: content-box;
    font-size: .857143em;
    max-width: 100%;
  }

  .mbsc-cal-events-i {
    max-height: 24em;
  }

  .mbsc-cal-events-sc {
    position: relative;
    -webkit-backface-visibility: hidden;
  }

  .mbsc-cal-event {
    line-height: 1.666667em;
    list-style: none;
    position: relative;
    white-space: nowrap;
    cursor: pointer;
  }

  .mbsc-ltr .mbsc-cal-events .mbsc-cal-event {
    padding-right: 5em;
    text-align: left;
  }

  .mbsc-rtl .mbsc-cal-events .mbsc-cal-event {
    padding-left: 5em;
    text-align: right;
  }

  .mbsc-cal-event-color {
    position: absolute;
    top: 0;
    left: 0;
    width: .5em;
    height: 100%;
  }

  .mbsc-cal-event-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-cal-event-time {
    display: inline-block;
    width: 5em;
    white-space: normal;
    line-height: 1.166667em;
    vertical-align: middle;
  }

  .mbsc-cal-event-dur {
    position: absolute;
    top: 50%;
    height: 2em;
    margin-top: -1em;
    line-height: 2em;
  }

  .mbsc-ltr .mbsc-cal-event-dur {
    right: 1em;
  }

  .mbsc-rtl .mbsc-cal-event-dur {
    left: 1em;
  }

  /* Event list styling */

  .mbsc-event-list {
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mbsc-event-list-h {
    display: none;
  }

  .mbsc-event-list .mbsc-lv .mbsc-lv-gr-title {
    padding: .5em 1em;
    line-height: 1.5em;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0;
  }

  .mbsc-event-list .mbsc-lv .mbsc-lv-item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: .875em;
    padding: .714286em 1em;
    cursor: pointer;
  }

  /* Empty view */

  .mbsc-event-list .mbsc-lv .mbsc-lv-gr-title.mbsc-event-empty {
    text-transform: none;
    font-size: 1em;
  }

  .mbsc-ltr .mbsc-event-list {
    text-align: left;
  }

  .mbsc-rtl .mbsc-event-list {
    text-align: right;
  }

  .mbsc-event-color,
  .mbsc-event-time {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .mbsc-event-time {
    width: 6em;
    font-size: .714286em;
    line-height: 1.6;
    min-height: 3.2em;
    opacity: .7;
  }

  .mbsc-ltr .mbsc-event-time {
    text-align: right;
  }

  .mbsc-rtl .mbsc-event-time {
    text-align: left;
  }

  .mbsc-event-color {
    width: 1.5px;
    margin: -0.285714em 0.714286em;
  }

  .mbsc-event-txt {
    line-height: 1.142857;
  }

  /* Desktop grid mode */

  .mbsc-ev-cal.mbsc-fr-md {
    .mbsc-cal-hdr {
      padding: .5em;
    }

    .mbsc-cal-scroll-c {
      margin-left: -1px;
    }

    .mbsc-cal-day-i {
      min-height: 4em;
    }

    .mbsc-cal-marks {
      top: 100%;
    }

    .mbsc-cal-txt,
    .mbsc-cal-txt-ph,
    .mbsc-cal-txt-more {
      font-size: .75em;
      height: 1.5em;
      line-height: 1.5em;
      padding: 0 .333334em;
      margin: 0 .333334em .166667em .333334em;
    }
  }

  /* Scrollbar */

  .mbsc-cal-events-i:hover .mbsc-sc-bar-c {
    opacity: 1;
  }
}


@include exports("eventcalendar.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-cal-events-popup {
      .mbsc-fr-w {
        border-radius: 0;
      }

      .mbsc-fr-bubble-top .mbsc-fr-arr {
        border-bottom-color: transparent;
      }
    }

    .mbsc-cal-events {
      width: 32em;
    }

    .mbsc-cal-event {
      padding: 1em 1em 1em 1.25em;
    }

    /* Desktop grid mode */
    &.mbsc-ev-cal.mbsc-fr-md {
      .mbsc-cal-c {
        padding: 0;
      }

      .mbsc-cal-days > div {
        border: 0;
        padding: 0 .785714em;
        font-size: .875em;
      }

      .mbsc-cal-picker {
        padding-top: 1.875em;
        padding-bottom: 0;
      }

      .mbsc-cal-day-date {
        margin: .230769em;
        margin-bottom: .461538em;
      }

      .mbsc-cal-has-labels .mbsc-cal-day-i {
        min-height: 5.125em;
      }

      .mbsc-cal-marks {
        margin: -.5em 1em 0 1em;
      }

      .mbsc-ltr {

        .mbsc-cal-days,
        .mbsc-cal-day {
          text-align: left;
        }
      }

      .mbsc-rtl {

        .mbsc-cal-days,
        .mbsc-cal-day {
          text-align: right;
        }
      }
    }
  }

  @include mbsc-mobiscroll-eventcalendar(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// measurement component






@mixin mbsc-mobiscroll-measurement($theme, $params) {
  @include exports("measurement.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}




@include exports("measurement") {
  .mbsc-msr .mbsc-sc-whl-gr {
    direction: ltr;
  }

  .mbsc-msr .mbsc-rtl .mbsc-sc-whl-w {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .mbsc-msr .mbsc-rtl .mbsc-msr-whl-unit {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    order: 1;
  }
}


@include exports("measurement.mobiscroll") {
  @include mbsc-mobiscroll-measurement(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// navigation component






@mixin mbsc-mobiscroll-scrollview($theme, $params) {
  @include exports("scrollview.#{$theme}.colors") {}
}




@include exports("scrollview") {
  .mbsc-scv-c {
    overflow: hidden;
    position: relative;
    font-size: 16px;
    font-family: FSHackney-Bold, verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -ms-touch-action: pan-y;
    user-select: none;
    touch-action: pan-y;
    /*height: 100%;*/
  }

  .mbsc-scv,
  .mbsc-scv-sc {
    position: relative;
  }

  .mbsc-rtl {
    direction: rtl;
  }


  /* Flex */

  .mbsc-scv,
  .mbsc-scv-sc,
  .mbsc-scv-item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 100%;
  }

  // Needed by IE and iOS9
  .mbsc-scv,
  .mbsc-scv-item {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .mbsc-scv.mbsc-comp {
    padding: 0;
    margin: 0 auto;
    list-style: none;
    line-height: normal;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .mbsc-scv-item {
    height: auto;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    box-sizing: border-box;
  }

  /* Liquid layout */

  .mbsc-scv-liq,
  .mbsc-scv-liq .mbsc-scv-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}


@include exports("scrollview.mobiscroll") {
  @include mbsc-mobiscroll-scrollview(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}





@mixin mbsc-mobiscroll-navigation-base($theme, $params) {
  @include exports("navigation-base.#{$theme}.colors") {

    @include mbsc-mobiscroll-scrollview($theme, $params);

    $background: map-get($params, background);
    $text: map-get($params, text);
    $accent: map-get($params, accent);

    $colors: mbsc-mobiscroll-colors($params);
    $border: map-get($colors, border);

    .mbsc-#{$theme} {
      &.mbsc-ms-c {
        background: $background;
      }

      .mbsc-ms .mbsc-ms-item {
        color: $text;
      }

      &.mbsc-ms-c .mbsc-scv-sc .mbsc-ms .mbsc-ms-item.mbsc-active {
        background: rgba($accent, .3);
      }

      &.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
        background: rgba($accent, .1);
      }

      &.mbsc-ms-b {
        .mbsc-ms-item-sel {
          border-bottom-color: $accent;
        }

        &.mbsc-ms-top {
          border-bottom: 1px solid $accent;
        }

        &.mbsc-ms-bottom {
          border-top: 1px solid $accent;

          .mbsc-ms-item-sel {
            border-top-color: $accent;
            border-bottom-color: transparent;
          }
        }
      }

      &.mbsc-ms-a {
        &.mbsc-ms-top {
          border-bottom: 1px solid $border;
        }

        &.mbsc-ms-bottom {
          border-top: 1px solid $border;
        }

        .mbsc-ms-item-sel {
          color: $accent;
        }
      }
    }
  }
}





@include exports("navigation-base") {
  .mbsc-ms-c {
    text-align: center;
  }

  .mbsc-ms-top.mbsc-ms-c,
  .mbsc-ms-bottom.mbsc-ms-c {
    z-index: 9999;
    position: fixed;
    left: 0;
    right: 0;
    height: auto;
  }

  .mbsc-ms-top.mbsc-ms-c .mbsc-ms,
  .mbsc-ms-bottom.mbsc-ms-c .mbsc-ms {
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }

  .mbsc-ms-top.mbsc-ms-c {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }

  .mbsc-ms-bottom.mbsc-ms-c {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mbsc-ms-top.mbsc-scv-ctx,
  .mbsc-ms-bottom.mbsc-scv-ctx {
    position: absolute;
  }

  .mbsc-ms-top {
    top: 0;
  }

  .mbsc-ms-bottom {
    bottom: 0;
  }

  .mbsc-ms {
    text-align: center;
    white-space: nowrap;
  }

  .mbsc-ms-item {
    position: relative;
    margin: 0;
    font-size: 1em;
    line-height: 1.25;
    min-width: 4em;
    padding: .75em .5em;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    transition-property: color, background, border-color, text-shadow;
    transition-duration: 200ms;
  }

  .mbsc-scv-hasw .mbsc-ms-item {
    white-space: normal;
    min-width: 0;
  }

  .mbsc-ms.mbsc-comp a.mbsc-ms-item {
    font-weight: normal; //color: inherit;
    text-decoration: none;
  }

  .mbsc-ms .mbsc-ms-item.mbsc-disabled {
    opacity: .5;
    cursor: not-allowed;
  }


  /* Icon styling */

  .mbsc-ms-icons .mbsc-ms-item {
    min-width: 6em;
    padding: 1.666667em .5em;
    font-size: .75em;
  }


  /* Icon + text styling */

  .mbsc-ms-icons.mbsc-ms-txt .mbsc-ms-item {
    padding-top: 3em;
    padding-bottom: .5em;
  }

  .mbsc-ms-ic:before {
    position: absolute;
    top: .25em;
    left: 0;
    right: 0;
    display: block;
    text-align: center;
    line-height: 1em;
  }

  .mbsc-ms-base .mbsc-ms-ic:before {
    font-size: 2em;
  }
}


@include exports("navigation-base.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-ms-b {
      .mbsc-ms-item {
        border: 2px solid transparent;
        border-width: 2px 0;
      }
    }
  }

  @include mbsc-mobiscroll-navigation-base(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}







@mixin mbsc-mobiscroll-navigation($theme, $params) {
  @include exports("navigation.#{$theme}.colors") {
    @include mbsc-mobiscroll-navigation-base($theme, $params);
    @include mbsc-mobiscroll-popup($theme, $params);
  }
}





@include exports("navigation") {
  .mbsc-ms-badge {
    position: absolute;
    top: .25em;
    min-width: 1.666667em;
    height: 1.666667em;
    padding: 0 .25em;
    border-radius: 2em;
    line-height: 1.666667em;
    background: red;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
  }

  .mbsc-ltr .mbsc-ms-badge {
    left: 50%;
    margin-left: .5em;
  }

  .mbsc-rtl .mbsc-ms-badge {
    right: 50%;
    margin-right: .5em;
  }

  .mbsc-ms-txt:not(.mbsc-ms-icons) {
    .mbsc-ms-badge {
      font-size: .75em;
    }

    &.mbsc-ltr .mbsc-ms-badge {
      left: auto;
      right: 0;
    }

    &.mbsc-rtl .mbsc-ms-badge {
      left: 0;
      right: auto;
    }
  }

  /* Show more item */

  .mbsc-ms-item.mbsc-menu-item-ic {
    padding: 1.666667em .5em;
    font-size: .75em;

    &:before {
      top: 50%;
      margin-top: -.5em;
    }
  }

  .mbsc-ms-icons .mbsc-ms-item.mbsc-menu-item-ic:before {
    top: .25em;
    margin-top: 0;
  }


  /* Hamburger Menu icon */

  .mbsc-ms-hamburger.mbsc-ms-c.mbsc-ms-inline {
    display: inline-block;
    vertical-align: middle;
    background: none;
    border: 0;
  }


  /* Show More bubble */

  .mbsc-ms-more {
    .mbsc-ms {
      display: block;
    }

    .mbsc-ms-item {
      min-height: 2.857143em;
    }

    .mbsc-ms-item.mbsc-ms-ic:before {
      top: 50%;
      width: 2.333334em;
      margin-top: -.5em;
      line-height: 1em;
      font-size: 1.714286em;
    }

    .mbsc-ms-badge {
      top: 50%;
      font-size: .857143em;
      margin-top: -0.833333em;
    }

    /* LTR specific */
    .mbsc-ltr {
      .mbsc-ms {
        text-align: left;

        .mbsc-ms-ic {
          padding-left: 4em;
        }
      }

      .mbsc-ms-ic:before {
        right: auto;
      }

      .mbsc-ms-badge {
        left: auto;
        right: .5em;
        margin-left: 0;
      }
    }

    /* RTL specific */
    .mbsc-rtl {
      .mbsc-ms {
        text-align: right;

        .mbsc-ms-ic {
          padding-right: 4em;
        }
      }

      .mbsc-ms-ic:before {
        left: auto;
      }

      .mbsc-ms-badge {
        right: auto;
        left: .5em;
        margin-right: 0;
      }
    }

    /* Icons only */
    &.mbsc-ms-more-icons {
      .mbsc-fr-popup .mbsc-fr-w {
        min-width: 0;
      }

      .mbsc-wdg-c.mbsc-w-p {
        padding: 0;
      }

      .mbsc-ms .mbsc-ms-ic {
        padding-left: .714286em;
        padding-right: .714286em;
      }

      .mbsc-ms-ic:before {
        position: static;
        width: 1.5em;
        margin: 0;
      }
    }
  }
}


@include exports("navigation.mobiscroll") {
  @include mbsc-mobiscroll-navigation(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// scrollview component



// optionlist component






@mixin mbsc-mobiscroll-optionlist($theme, $params) {
  @include exports("optionlist.#{$theme}.colors") {
    @include mbsc-mobiscroll-navigation-base($theme, $params);
  }
}





@include exports("optionlist.mobiscroll") {
  @include mbsc-mobiscroll-optionlist(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// cards component






@mixin mbsc-mobiscroll-cards($theme, $params) {
  @include exports("cards.#{$theme}.colors") {

    @include mbsc-mobiscroll-forms($theme, $params);

    $background: map-get($params, background);

    $colors: mbsc-mobiscroll-colors($params);
    $border: map-get($colors, border);

    .mbsc-#{$theme} {
      &.mbsc-card.mbsc-form {
        background: lighten($background, 4%);
      }

      &.mbsc-card .mbsc-lv-item {
        background: lighten($background, 4%);
      }

      .mbsc-card-footer {
        border-color: $border;
      }

      &.mbsc-card .mbsc-input.mbsc-input-outline .mbsc-label {
        background: lighten($background, 4%);
      }
    }
  }
}





@include exports("cards") {

  [mbsc-card]:not(.mbsc-card) {
    // Prevent FOUC
    visibility: hidden;
  }

  .mbsc-card {
    position: relative;
    z-index: 1;
    margin: .75em;
    border-radius: 2px;
    overflow: hidden;
  }

  .mbsc-card-header {
    padding: 1em;
    overflow: hidden;
  }

  .mbsc-card-subtitle {
    color: #7c7c7c;
  }

  .mbsc-card-content {
    padding: 1em;
    overflow: hidden;
  }

  .mbsc-card-content > p:first-child {
    margin-top: 0;
  }

  .mbsc-card-content > p:last-child {
    margin-bottom: 0;
  }

  .mbsc-card-content.mbsc-no-padding {
    padding: 0;
  }

  .mbsc-card-header + .mbsc-card-content {
    padding-top: 0;
  }

  .mbsc-card-footer {
    padding: 1em;
    border-top: 1px solid transparent;
    clear: both;
  }

  /* Card elemets */

  .mbsc-card h2.mbsc-card-title,
  .mbsc-card-title {
    font-size: 1.5em;
    font-weight: normal;
    margin: 0;
  }

  .mbsc-card h3.mbsc-card-subtitle,
  .mbsc-card-subtitle {
    font-size: 0.875em;
    font-weight: normal;
    margin: 0;
  }

  .mbsc-card h3.mbsc-card-subtitle + *,
  .mbsc-card-subtitle + *,
  .mbsc-card h2.mbsc-card-title + *,
  .mbsc-card-title + * {
    margin-top: .25em;
  }

  /* Card image styling */

  .mbsc-card video,
  .mbsc-card img:not(.mbsc-avatar):not(.mbsc-img-thumbnail) {
    display: block;
    width: 100%;
  }

  .mbsc-card.mbsc-ltr .mbsc-avatar {
    float: left;
    margin-right: .5em;
  }

  .mbsc-card.mbsc-rtl .mbsc-avatar {
    float: right;
    margin-left: .5em;
  }

  .mbsc-avatar ~ .mbsc-card-title,
  mbsc-avatar ~ .mbsc-card-title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
  }

  /* Form overrides */

  .mbsc-card .mbsc-card-footer .mbsc-btn {
    margin: -.6875em 0 -.6875em 0;
  }

  .mbsc-card .mbsc-card-content .mbsc-control-w {
    margin-left: 5;
    margin-right: 5;
  }

  /* Listview overrides */

  .mbsc-lv .mbsc-lv-item.mbsc-card {
    margin: .75em;
    padding: 0;
  }

  .mbsc-card.mbsc-form .mbsc-lv-cont {
    margin: 0;
  }

  .mbsc-card .mbsc-card-content .mbsc-lv-cont {
    margin: 0 -1em;
  }

  .mbsc-card-list .mbsc-lv-cont .mbsc-lv,
  .mbsc-lv-cont .mbsc-card-list.mbsc-lv {
    background: none;
  }

  .mbsc-card-list .mbsc-lv-item:not(.mbsc-card) {
    padding: 0;
    background: none;
  }
}


@include exports("cards.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-card.mbsc-form {
      box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
    }
  }

  @include mbsc-mobiscroll-cards(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// popup component



// grid-layout component



@mixin mbsc-grid-sm() {
  .mbsc-col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-sm-0 {
    margin-left: 0;
  }

  .mbsc-offset-sm-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-sm-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-sm-3 {
    margin-left: 25%;
  }

  .mbsc-offset-sm-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-sm-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-sm-6 {
    margin-left: 50%;
  }

  .mbsc-offset-sm-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-sm-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-sm-9 {
    margin-left: 75%;
  }

  .mbsc-offset-sm-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-sm-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-sm-0 {
    left: auto;
  }

  .mbsc-push-sm-1 {
    left: 8.33333333%;
  }

  .mbsc-push-sm-2 {
    left: 16.66666667%;
  }

  .mbsc-push-sm-3 {
    left: 25%;
  }

  .mbsc-push-sm-4 {
    left: 33.33333333%;
  }

  .mbsc-push-sm-5 {
    left: 41.66666667%;
  }

  .mbsc-push-sm-6 {
    left: 50%;
  }

  .mbsc-push-sm-7 {
    left: 58.33333333%;
  }

  .mbsc-push-sm-8 {
    left: 66.66666667%;
  }

  .mbsc-push-sm-9 {
    left: 75%;
  }

  .mbsc-push-sm-10 {
    left: 83.33333333%;
  }

  .mbsc-push-sm-11 {
    left: 91.66666667%;
  }

  .mbsc-push-sm-12 {
    left: 100%;
  }

  .mbsc-pull-sm-0 {
    right: auto;
  }

  .mbsc-pull-sm-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-sm-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-sm-3 {
    right: 25%;
  }

  .mbsc-pull-sm-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-sm-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-sm-6 {
    right: 50%;
  }

  .mbsc-pull-sm-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-sm-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-sm-9 {
    right: 75%;
  }

  .mbsc-pull-sm-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-sm-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-sm-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-md() {
  .mbsc-col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-md-0 {
    margin-left: 0;
  }

  .mbsc-offset-md-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-md-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-md-3 {
    margin-left: 25%;
  }

  .mbsc-offset-md-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-md-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-md-6 {
    margin-left: 50%;
  }

  .mbsc-offset-md-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-md-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-md-9 {
    margin-left: 75%;
  }

  .mbsc-offset-md-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-md-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-md-0 {
    left: auto;
  }

  .mbsc-push-md-1 {
    left: 8.33333333%;
  }

  .mbsc-push-md-2 {
    left: 16.66666667%;
  }

  .mbsc-push-md-3 {
    left: 25%;
  }

  .mbsc-push-md-4 {
    left: 33.33333333%;
  }

  .mbsc-push-md-5 {
    left: 41.66666667%;
  }

  .mbsc-push-md-6 {
    left: 50%;
  }

  .mbsc-push-md-7 {
    left: 58.33333333%;
  }

  .mbsc-push-md-8 {
    left: 66.66666667%;
  }

  .mbsc-push-md-9 {
    left: 75%;
  }

  .mbsc-push-md-10 {
    left: 83.33333333%;
  }

  .mbsc-push-md-11 {
    left: 91.66666667%;
  }

  .mbsc-push-md-12 {
    left: 100%;
  }

  .mbsc-pull-md-0 {
    right: auto;
  }

  .mbsc-pull-md-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-md-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-md-3 {
    right: 25%;
  }

  .mbsc-pull-md-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-md-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-md-6 {
    right: 50%;
  }

  .mbsc-pull-md-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-md-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-md-9 {
    right: 75%;
  }

  .mbsc-pull-md-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-md-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-md-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-lg() {
  .mbsc-col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-lg-0 {
    margin-left: 0;
  }

  .mbsc-offset-lg-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-lg-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-lg-3 {
    margin-left: 25%;
  }

  .mbsc-offset-lg-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-lg-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-lg-6 {
    margin-left: 50%;
  }

  .mbsc-offset-lg-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-lg-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-lg-9 {
    margin-left: 75%;
  }

  .mbsc-offset-lg-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-lg-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-lg-0 {
    left: auto;
  }

  .mbsc-push-lg-1 {
    left: 8.33333333%;
  }

  .mbsc-push-lg-2 {
    left: 16.66666667%;
  }

  .mbsc-push-lg-3 {
    left: 25%;
  }

  .mbsc-push-lg-4 {
    left: 33.33333333%;
  }

  .mbsc-push-lg-5 {
    left: 41.66666667%;
  }

  .mbsc-push-lg-6 {
    left: 50%;
  }

  .mbsc-push-lg-7 {
    left: 58.33333333%;
  }

  .mbsc-push-lg-8 {
    left: 66.66666667%;
  }

  .mbsc-push-lg-9 {
    left: 75%;
  }

  .mbsc-push-lg-10 {
    left: 83.33333333%;
  }

  .mbsc-push-lg-11 {
    left: 91.66666667%;
  }

  .mbsc-push-lg-12 {
    left: 100%;
  }

  .mbsc-pull-lg-0 {
    right: auto;
  }

  .mbsc-pull-lg-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-lg-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-lg-3 {
    right: 25%;
  }

  .mbsc-pull-lg-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-lg-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-lg-6 {
    right: 50%;
  }

  .mbsc-pull-lg-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-lg-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-lg-9 {
    right: 75%;
  }

  .mbsc-pull-lg-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-lg-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-lg-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-xl() {
  .mbsc-col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-xl-0 {
    margin-left: 0;
  }

  .mbsc-offset-xl-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-xl-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-xl-3 {
    margin-left: 25%;
  }

  .mbsc-offset-xl-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-xl-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-xl-6 {
    margin-left: 50%;
  }

  .mbsc-offset-xl-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-xl-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-xl-9 {
    margin-left: 75%;
  }

  .mbsc-offset-xl-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-xl-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-xl-0 {
    left: auto;
  }

  .mbsc-push-xl-1 {
    left: 8.33333333%;
  }

  .mbsc-push-xl-2 {
    left: 16.66666667%;
  }

  .mbsc-push-xl-3 {
    left: 25%;
  }

  .mbsc-push-xl-4 {
    left: 33.33333333%;
  }

  .mbsc-push-xl-5 {
    left: 41.66666667%;
  }

  .mbsc-push-xl-6 {
    left: 50%;
  }

  .mbsc-push-xl-7 {
    left: 58.33333333%;
  }

  .mbsc-push-xl-8 {
    left: 66.66666667%;
  }

  .mbsc-push-xl-9 {
    left: 75%;
  }

  .mbsc-push-xl-10 {
    left: 83.33333333%;
  }

  .mbsc-push-xl-11 {
    left: 91.66666667%;
  }

  .mbsc-push-xl-12 {
    left: 100%;
  }

  .mbsc-pull-xl-0 {
    right: auto;
  }

  .mbsc-pull-xl-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-xl-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-xl-3 {
    right: 25%;
  }

  .mbsc-pull-xl-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-xl-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-xl-6 {
    right: 50%;
  }

  .mbsc-pull-xl-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-xl-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-xl-9 {
    right: 75%;
  }

  .mbsc-pull-xl-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-xl-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-xl-12 {
    right: 100%;
  }
}

@include exports("grid-layout") {

  .mbsc-grid,
  .mbsc-grid-unresp,
  .mbsc-grid-fixed {
    width: 100%;
    padding-right: 1em;
    padding-left: 1em;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }
  }

  .mbsc-no-padding .mbsc-col,
  .mbsc-no-padding [class*="mbsc-col-"],
  .mbsc-form-grid .mbsc-col,
  .mbsc-form-grid [class*="mbsc-col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .mbsc-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -1em;
    margin-left: -1em;
  }

  .mbsc-col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-1,
  .mbsc-col-2,
  .mbsc-col-3,
  .mbsc-col-4,
  .mbsc-col-5,
  .mbsc-col-6,
  .mbsc-col-7,
  .mbsc-col-8,
  .mbsc-col-9,
  .mbsc-col-10,
  .mbsc-col-11,
  .mbsc-col-12,
  .mbsc-col,
  .mbsc-col-auto,
  .mbsc-col-sm-1,
  .mbsc-col-sm-2,
  .mbsc-col-sm-3,
  .mbsc-col-sm-4,
  .mbsc-col-sm-5,
  .mbsc-col-sm-6,
  .mbsc-col-sm-7,
  .mbsc-col-sm-8,
  .mbsc-col-sm-9,
  .mbsc-col-sm-10,
  .mbsc-col-sm-11,
  .mbsc-col-sm-12,
  .mbsc-col-sm,
  .mbsc-col-sm-auto,
  .mbsc-col-md-1,
  .mbsc-col-md-2,
  .mbsc-col-md-3,
  .mbsc-col-md-4,
  .mbsc-col-md-5,
  .mbsc-col-md-6,
  .mbsc-col-md-7,
  .mbsc-col-md-8,
  .mbsc-col-md-9,
  .mbsc-col-md-10,
  .mbsc-col-md-11,
  .mbsc-col-md-12,
  .mbsc-col-md,
  .mbsc-col-md-auto,
  .mbsc-col-lg-1,
  .mbsc-col-lg-2,
  .mbsc-col-lg-3,
  .mbsc-col-lg-4,
  .mbsc-col-lg-5,
  .mbsc-col-lg-6,
  .mbsc-col-lg-7,
  .mbsc-col-lg-8,
  .mbsc-col-lg-9,
  .mbsc-col-lg-10,
  .mbsc-col-lg-11,
  .mbsc-col-lg-12,
  .mbsc-col-lg,
  .mbsc-col-lg-auto,
  .mbsc-col-xl-1,
  .mbsc-col-xl-2,
  .mbsc-col-xl-3,
  .mbsc-col-xl-4,
  .mbsc-col-xl-5,
  .mbsc-col-xl-6,
  .mbsc-col-xl-7,
  .mbsc-col-xl-8,
  .mbsc-col-xl-9,
  .mbsc-col-xl-10,
  .mbsc-col-xl-11,
  .mbsc-col-xl-12,
  .mbsc-col-xl,
  .mbsc-col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 1em;
    padding-left: 1em;
  }

  .mbsc-col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-offset-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-3 {
    margin-left: 25%;
  }

  .mbsc-offset-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-6 {
    margin-left: 50%;
  }

  .mbsc-offset-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-9 {
    margin-left: 75%;
  }

  .mbsc-offset-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-11 {
    margin-left: 91.666667%;
  }

  .mbsc-grid-sm {
    &.mbsc-grid-fixed {
      max-width: 540px;
    }

    @include mbsc-grid-sm();
  }

  @media (min-width: 576px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 540px;
      }

      @include mbsc-grid-sm();
    }
  }

  .mbsc-grid-md {
    &.mbsc-grid-fixed {
      max-width: 720px;
    }

    @include mbsc-grid-md();
  }

  @media (min-width: 768px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 720px;
      }

      @include mbsc-grid-md();
    }
  }

  .mbsc-grid-lg {
    &.mbsc-grid-fixed {
      max-width: 960px;
    }

    @include mbsc-grid-lg();
  }

  @media (min-width: 992px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 960px;
      }

      @include mbsc-grid-lg();
    }
  }

  .mbsc-grid-xl {
    &.mbsc-grid-fixed {
      max-width: 1140px;
    }

    @include mbsc-grid-xl();
  }

  @media (min-width: 1200px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 1140px;
      }

      @include mbsc-grid-xl();
    }
  }

  .mbsc-align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .mbsc-align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .mbsc-align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .mbsc-justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .mbsc-justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .mbsc-justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .mbsc-justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .mbsc-justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
}




// Theme builder function for mobiscroll theme

@mixin mbsc-mobiscroll-theme($theme, $colors) {
    @include mbsc-mobiscroll-select($theme, $colors);
    @include mbsc-mobiscroll-datetime($theme, $colors);
    @include mbsc-mobiscroll-image($theme, $colors);
    @include mbsc-mobiscroll-treelist($theme, $colors);
    @include mbsc-mobiscroll-calendar($theme, $colors);
    @include mbsc-mobiscroll-timespan($theme, $colors);
    @include mbsc-mobiscroll-timer($theme, $colors);
    @include mbsc-mobiscroll-color($theme, $colors);
    @include mbsc-mobiscroll-range($theme, $colors);
    @include mbsc-mobiscroll-scroller($theme, $colors);
    @include mbsc-mobiscroll-listview($theme, $colors);
    @include mbsc-mobiscroll-number($theme, $colors);
    @include mbsc-mobiscroll-numpad($theme, $colors);
    @include mbsc-mobiscroll-forms($theme, $colors);
    @include mbsc-mobiscroll-eventcalendar($theme, $colors);
    @include mbsc-mobiscroll-measurement($theme, $colors);
    @include mbsc-mobiscroll-navigation($theme, $colors);
    @include mbsc-mobiscroll-scrollview($theme, $colors);
    @include mbsc-mobiscroll-optionlist($theme, $colors);
    @include mbsc-mobiscroll-cards($theme, $colors);
    @include mbsc-mobiscroll-popup($theme, $colors);
}

@mixin mbsc-custom-theme($theme, $base-theme, $colors) {
    @if $base-theme==mobiscroll {
        @include mbsc-mobiscroll-theme($theme, $colors);
    }
}

$colors-mobiscroll-dark: (
    background: $mbsc-mobiscroll-dark-background,
    text: $mbsc-mobiscroll-dark-text,
    accent: $mbsc-mobiscroll-dark-accent
);
@include mbsc-mobiscroll-theme(mobiscroll-dark, $colors-mobiscroll-dark);

